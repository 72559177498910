import React, {type FC} from 'react';

import logoGray from './assets/logo-gray.svg';
import './EmptyUnitPage.scss';

const EmptyUnitPage: FC = ({children}) => {
  return (
    <div className="empty-unit-page">
      <div className="logo">
        <img src={logoGray} alt="" />
      </div>
      {children}
    </div>
  );
};

export default EmptyUnitPage;
