import {type IntlShape} from 'react-intl';

import {coursebookLibraryMessages} from '../../messages';
import {type UserV2} from '../../interface';
import toValidFilter from '../../../../helpers/toValidFilter';
import {type NumberOptionType, type OptionsType} from '../../../ReactSelect/interface';

export function mapAuthorsToOptions(authorsArr: UserV2[]): NumberOptionType[] {
  return authorsArr.map(author => ({
    label: `${author.profile.lastName} ${author.profile.firstName}`,
    value: author.id
  }));
}

export const methodologyOptionId = -1;
export function getMethodologyOption({formatMessage}: IntlShape) {
  return {
    label: formatMessage(coursebookLibraryMessages.EnglexDepartmentOfMethodology),
    value: methodologyOptionId
  };
}

export function filterAuthors(authorsArr: OptionsType<NumberOptionType>, initialFilter: string) {
  const filter = toValidFilter(initialFilter);
  const stringBeginningRegexp = new RegExp(`^${filter}`, 'i');
  const matchesInBeginningOfString = authorsArr.filter(author =>
    author.label.match(stringBeginningRegexp)
  );

  const afterFirstSpaceRegexp = new RegExp(`^\\S*\\s${filter}`, 'i');
  const matchesAfterFirstSpace = authorsArr.filter(
    author =>
      !matchesInBeginningOfString.includes(author) && author.label.match(afterFirstSpaceRegexp)
  );

  const otherMatchesRegexp = new RegExp(`${filter}`, 'i');
  const otherMatches = authorsArr.filter(
    author =>
      !matchesInBeginningOfString.includes(author) &&
      !matchesAfterFirstSpace.includes(author) &&
      author.label.match(otherMatchesRegexp)
  );

  return matchesInBeginningOfString.concat(matchesAfterFirstSpace).concat(otherMatches);
}
