import {type Action} from 'redux';

import {type CoursebookLibraryState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  ADD_TO_COURSEBOOK_LIST,
  CLEAR_COURSEBOOKS_LIST,
  CLEAR_LIBRARY,
  CLOSE_EXTENDED_SEARCH_PANEL,
  OPEN_EXTENDED_SEARCH_PANEL,
  SET_AVAILABLE_AUTHORS,
  SET_COURSEBOOK_LIST,
  SET_LIBRARY_ERROR,
  SET_LIBRARY_UPDATING,
  SET_TOTAL_COURSEBOOKS_COUNT
} from '../actions/actionTypes';
import {type ServerCoursebook as CoursebookServerSide} from '../interface';
import {serverCoursebookToClientCoursebook} from '../helpers';
import {
  type SetAvailableAuthorsAction,
  type SetCoursebookListAction,
  type SetLibraryUpdatingAction,
  type SetTotalCountAction
} from '../actions/action';

const ACTION_HANDLERS: ActionHandlersList<CoursebookLibraryState, Action> = {
  [SET_COURSEBOOK_LIST]: (
    state: CoursebookLibraryState,
    action: SetCoursebookListAction
  ): CoursebookLibraryState => ({
    ...state,
    coursebooks: action.list.map((c: CoursebookServerSide) => serverCoursebookToClientCoursebook(c))
  }),
  [SET_TOTAL_COURSEBOOKS_COUNT]: (
    state: CoursebookLibraryState,
    {totalCount}: SetTotalCountAction
  ): CoursebookLibraryState => ({
    ...state,
    totalCount
  }),
  [ADD_TO_COURSEBOOK_LIST]: (
    state: CoursebookLibraryState,
    action: SetCoursebookListAction
  ): CoursebookLibraryState => ({
    ...state,
    coursebooks: state.coursebooks!.concat(
      action.list.map((c: CoursebookServerSide) => serverCoursebookToClientCoursebook(c))
    )
  }),
  [SET_LIBRARY_UPDATING]: (
    state: CoursebookLibraryState,
    {isUpdating}: SetLibraryUpdatingAction
  ): CoursebookLibraryState => ({...state, updatingLibrary: isUpdating}),
  [CLEAR_LIBRARY]: (): CoursebookLibraryState => ({...initialCoursebookLibraryState}),
  [SET_LIBRARY_ERROR]: (state: CoursebookLibraryState): CoursebookLibraryState => ({
    ...state,
    hasError: true
  }),
  [CLEAR_COURSEBOOKS_LIST]: (state: CoursebookLibraryState): CoursebookLibraryState => ({
    ...state,
    coursebooks: undefined
  }),
  [SET_AVAILABLE_AUTHORS]: (
    state: CoursebookLibraryState,
    {availableOriginalAuthors, availableAuthors}: SetAvailableAuthorsAction
  ): CoursebookLibraryState => ({
    ...state,
    availableAuthors,
    availableOriginalAuthors
  }),
  [OPEN_EXTENDED_SEARCH_PANEL]: (state: CoursebookLibraryState): CoursebookLibraryState => ({
    ...state,
    extendedSearchPanelOpen: true
  }),
  [CLOSE_EXTENDED_SEARCH_PANEL]: (state: CoursebookLibraryState): CoursebookLibraryState => ({
    ...state,
    extendedSearchPanelOpen: undefined
  })
};

const initialCoursebookLibraryState: CoursebookLibraryState = {};

export default function (
  state: CoursebookLibraryState = initialCoursebookLibraryState,
  action: Action
): CoursebookLibraryState {
  const reducer = ACTION_HANDLERS[action.type];
  const newState = reducer ? reducer(state, action) : {...state};
  return {
    ...newState
  };
}
