import {type XPreviewProperties} from 'store/exercise/player/preview/interface';
import {type CoursebookUnit, type Role} from 'store/interface';

export enum Preview {
  Intro = 'intro',
  Exercise = 'exercise'
}

export interface UnitPreviewProps {
  isLoading: boolean;
  xpreview: XPreviewProperties | null;
  changeRole: (role: Role) => void;
  pageCount: number | null;
  pageNumber?: number;
  selectPage: (page: number) => void;
  mainId: string | null;
  unitId: number;
  coursebookId: string;
  courseInstanceIsActive?: boolean;
  grammarPreview?: string;
  scrollToExerciseId?: string;
  unit: CoursebookUnit | null;
}
