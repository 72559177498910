const prefix = 'TEXTBOOK/';

export const TOGGLE_DETAILED_COURSEBOOK_INFO = prefix + 'TOGGLE_DETAILED_COURSEBOOK_INFO';
export const CONFIRM_REDIRECT = prefix + 'CONFIRM_REDIRECT';
export const DECLINE_REDIRECT = prefix + 'DECLINE_REDIRECT';
export const SET_UPDATING_COURSEBOOK = prefix + 'SET_UPDATING_COURSEBOOK';
export const SET_COURSEBOOK_INFO = prefix + 'SET_COURSEBOOK_INFO';
export const SET_COURSEBOOK_ERROR = prefix + 'SET_COURSEBOOK_ERROR';
export const RESET = prefix + 'RESET';
export const EDIT_COURSEBOOK_COVER = prefix + 'EDIT_COURSEBOOK_COVER';
export const SET_COURSEBOOK_COVER = prefix + 'SET_COURSEBOOK_COVER';
