const prefix = 'COURSEBOOK_LIBRARY_';

export const LOAD_COURSEBOOK_DATA = prefix + 'LOAD_COURSEBOOK_DATA';
export const REQUEST_COURSEBOOK_SECTIONS = prefix + 'REQUEST_COURSEBOOK_SECTIONS';
export const DELETE_COURSEBOOK = prefix + 'DELETE_COURSEBOOK';
export const OPEN_EXTENDED_SEARCH_PANEL = prefix + 'OPEN_EXTENDED_SEARCH_PANEL';
export const CLOSE_EXTENDED_SEARCH_PANEL = prefix + 'CLOSE_EXTENDED_SEARCH_PANEL';
export const SET_AVAILABLE_AUTHORS = prefix + 'SET_AVAILABLE_AUTHORS';
export const SET_LIBRARY_UPDATING = prefix + 'SET_LIBRARY_UPDATING';
export const CLEAR_LIBRARY = prefix + 'CLEAR_LIBRARY';
export const SET_COURSEBOOK_LIST = prefix + 'SET_COURSEBOOK_LIST';
export const SET_TOTAL_COURSEBOOKS_COUNT = prefix + 'SET_TOTAL_COURSEBOOKS_COUNT';
export const ADD_TO_COURSEBOOK_LIST = prefix + 'ADD_TO_COURSEBOOK_LIST';
export const SET_LIBRARY_ERROR = prefix + 'SET_LIBRARY_ERROR';
export const CLEAR_COURSEBOOKS_LIST = prefix + 'CLEAR_COURSEBOOKS_LIST';

export const REQUEST_COURSEBOOKS_LIST = prefix + 'REQUEST_COURSEBOOKS_LIST';
export const REQUEST_AVAILABLE_AUTHORS = prefix + 'REQUEST_AVAILABLE_AUTHORS';

export const COPY_COURSEBOOK_UNITS = prefix + 'COPY_COURSEBOOK_UNITS';
