import {type Action, type ActionCreator, type Dispatch as ReduxDispatch} from 'redux';
import {type IntlShape} from 'react-intl';
import {ValidationError} from 'yup';
import {List, Map} from 'immutable';
import {type Dispatch} from 'redux-axios-middleware';
import {captureException} from '@sentry/react';
import {type Value} from '@englex/slate';

import {type NavigateAction, push} from 'store/router';
import * as toastr from 'components/toastr';
import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {editorPath, unitPath} from 'common/paths';

import {CHANGE_GRAMMAR_HEADER, CHANGE_TITLE, NEW_EXERCISE} from '../actionTypes/xexercise';
import {type AppState} from '../../../interface';
import {
  type XEditorNewUrlParams,
  XEditorStage,
  type XEditorUrlParams,
  type XExerciseErrors
} from '../interface';
import validationMessages from '../widgets/i18n';
import {
  CHANGE_STAGE,
  REQUEST_SAVE_COURSEBOOK_UNIT_EXERCISE,
  RESET,
  VALIDATION_ERRORS
} from '../actionTypes/xeditor';
import {type XExerciseProperties, type XWidgetProperties} from '../widgets/interface';
import {type ExerciseJSON} from '../../player/interface';
import {
  type ChangeGrammarTitleAction,
  type ChangeTitleAction,
  type NewXExerciseAction,
  type XEditorStageAction,
  type XEditorValidationErrorAction
} from './interface';
import {
  ValidationGrammarTitleError,
  ValidationMediaSourceError,
  ValidationTitleError,
  ValidationWidgetError
} from '../widgets/customErrors';
import {deleteDraftExercise} from './xdraftexercises';

export const xexerciseChangeTitle: ActionCreator<ChangeTitleAction> = (title: string) => {
  return {
    type: CHANGE_TITLE,
    title
  };
};

export const xexerciseChangeGrammarHeader: ActionCreator<ChangeGrammarTitleAction> = (
  change: Value
) => {
  return {
    type: CHANGE_GRAMMAR_HEADER,
    change
  };
};

export const newXExercise: ActionCreator<NewXExerciseAction> = (xexercise: ExerciseJSON) => {
  return {
    type: NEW_EXERCISE,
    xexercise
  };
};

const transformErrors = (validationErrors?: Array<ValidationError>): XExerciseErrors =>
  validationErrors
    ? validationErrors.reduce((e: XExerciseErrors, ve: ValidationError) => {
        if (ValidationWidgetError.isInstanceof(ve)) {
          return e.setIn([ve.path, ve.widgetId], ve.widgetError);
        }

        if (ValidationMediaSourceError.isInstanceof(ve)) {
          return e.setIn([ve.path, ve.mediaSource.id], ve.mediaSourceError);
        }

        if (ValidationTitleError.isInstanceof(ve) || ValidationGrammarTitleError.isInstanceof(ve)) {
          return e.set(ve.path as string, List([ve.message]));
        }

        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.error('Unknown error type', ve);
        }

        return e;
      }, Map())
    : Map();

export const changeEditingState = (stage: XEditorStage): XEditorStageAction => ({
  type: CHANGE_STAGE,
  stage
});
export const saveExercise =
  (params: XEditorUrlParams, redirectAfterSaved: boolean) =>
  async (
    dispatch: Dispatch<AxiosRequestAction | NavigateAction | Action, AppState>,
    getState: () => AppState
  ): Promise<boolean> => {
    try {
      await dispatch(requestSaveExercise(params, getState().xeditor!.xexercise));

      dispatch(deleteDraftExercise(params.exerciseId!));

      if (redirectAfterSaved) {
        dispatch(
          push(unitPath(params.coursebookId, Number(params.unitId)), {
            state: {
              animatedElement: params.exerciseId
            }
          })
        );
      }
      return true;
    } catch (e) {
      const error: Error = e.error || e;
      if (error instanceof Error) {
        captureException(e);
      }
      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error while saving exercise', e);
      }
    } finally {
      dispatch(changeEditingState(XEditorStage.EDITING));
    }
    return false;
  };

export const resetXEditor: () => Action = () => ({
  type: RESET
});
export const addUnitExercise =
  (params: XEditorNewUrlParams, redirectToCoursebook: boolean) =>
  async (
    dispatch: Dispatch<AxiosRequestAction | NavigateAction | Action, AppState>,
    getState: () => AppState
  ): Promise<boolean> => {
    try {
      const response: AxiosResponseAction<{exerciseId: string}> = params.mainExerciseId
        ? await dispatch(requestAddSupplementaryUnitExercise(params, getState().xeditor!.xexercise))
        : await dispatch(requestAddUnitExercise(params, getState().xeditor!.xexercise));

      dispatch(deleteDraftExercise('null'));

      if (redirectToCoursebook) {
        dispatch(
          push(unitPath(params.coursebookId, Number(params.unitId)), {
            state: {exerciseId: response.payload.data.exerciseId}
          })
        );
      } else {
        dispatch(resetXEditor());
        dispatch(
          push(
            editorPath(params.coursebookId, Number(params.unitId), response.payload.data.exerciseId)
          )
        );
      }
      return true;
    } catch (e) {
      const error: Error = e.error || e;
      if (error instanceof Error) {
        captureException(e);
      }
      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error while saving exercise', e);
      }
    } finally {
      dispatch(changeEditingState(XEditorStage.EDITING));
    }
    return false;
  };

const requestSaveExercise: (
  params: XEditorUrlParams,
  exercise: XExerciseProperties
) => AxiosRequestAction = (
  {coursebookId, exerciseId, unitId}: XEditorUrlParams,
  exercise: XExerciseProperties
): AxiosRequestAction => ({
  type: REQUEST_SAVE_COURSEBOOK_UNIT_EXERCISE,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      data: exercise.toJSON({withMedia: true}),
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise/${exerciseId}`
    }
  }
});

const requestAddUnitExercise: (
  params: XEditorNewUrlParams,
  exercise: XExerciseProperties
) => AxiosRequestAction = (
  {coursebookId, unitId}: XEditorNewUrlParams,
  exercise: XExerciseProperties
): AxiosRequestAction => ({
  type: REQUEST_SAVE_COURSEBOOK_UNIT_EXERCISE,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      data: exercise.toJSON({withMedia: true}),
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise`
    }
  }
});

const requestAddSupplementaryUnitExercise: (
  params: XEditorNewUrlParams,
  exercise: XExerciseProperties
) => AxiosRequestAction = (
  {coursebookId, unitId, mainExerciseId}: XEditorNewUrlParams,
  exercise: XExerciseProperties
): AxiosRequestAction => ({
  type: REQUEST_SAVE_COURSEBOOK_UNIT_EXERCISE,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      data: exercise.toJSON({withMedia: true}),
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise/${mainExerciseId}`
    }
  }
});

export const xexerciseValidationErrors = (
  errors: XExerciseErrors
): XEditorValidationErrorAction => ({
  type: VALIDATION_ERRORS,
  errors
});
export const validate =
  (intl: IntlShape, scrollToError: boolean = true, editingOnSuccess: boolean = true) =>
  async (dispatch: ReduxDispatch<Action>, getState: () => AppState): Promise<boolean> => {
    const xexercise = getState().xeditor!.xexercise;
    xexercise.widgets.forEach((w: XWidgetProperties) => w.onBeforeValidation(dispatch));

    dispatch(changeEditingState(XEditorStage.BLOCK_EDITING));
    const schema = xexercise.schema(intl);
    const options = {
      strict: true,
      abortEarly: false // we are going to get all the errors, not just the first one
    };

    let validationErrors: ValidationError[] = [];

    try {
      // validate exercise as plain object, because yup has no schema for Immutable.Record, but it can be implemented
      // TODO: we can try to implement custom the yup schema for immutable record
      await schema.validate(
        {
          title: xexercise.title,
          grammarTitle: xexercise.grammarTitle,
          widgets: xexercise.widgets.reduce((o, w: XWidgetProperties) => ({...o, [w.id]: w}), {}),
          mediaSources: xexercise.mediaSources.toJS()
        },
        options
      );
    } catch (e) {
      if (e instanceof ValidationError) {
        validationErrors = e.inner;
        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.log('XExercise object yup validation error:', e);
        }
      } else {
        toastr.error(
          intl.formatMessage({id: 'Common.SomethingWrong'}),
          intl.formatMessage(validationMessages.InternalEditorError)
        );
        captureException(e);
        return false;
      }
    }

    const isValid = validationErrors.length === 0;
    if (isValid) {
      editingOnSuccess && dispatch(changeEditingState(XEditorStage.EDITING));
      return true;
    }

    const errors = transformErrors(validationErrors);
    dispatch(xexerciseValidationErrors(errors));
    dispatch(changeEditingState(XEditorStage.EDITING));
    if (scrollToError) {
      const errorNode = document.querySelector('.xeditor-validation-error');
      if (errorNode) {
        errorNode.scrollIntoView({behavior: 'smooth', block: 'end'});
      }
    }
    return false;
  };
