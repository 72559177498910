import React, {type FC} from 'react';
import classNames from 'classnames';

import Icon from './Icon';

import './EnglexCoursebookCover.scss';

interface Props {
  fontSize?: number;
  isCompleted?: boolean;
}

const EnglexCoursebookCover: FC<Props> = ({fontSize = 12, isCompleted}) => {
  const className = classNames('book-cover-placeholder', {deleted: isCompleted});
  return (
    <div className={className} style={{fontSize}}>
      <div className="padding-provider">
        <Icon name="enlx-logo" />
        <span className="title">Englex</span>
        <span className="title">Platform</span>
        <span className="title">Coursebook</span>
      </div>
    </div>
  );
};

export default EnglexCoursebookCover;
