export const libraryPath = () => '/library';

export const coursebookPath = (coursebookId: string) =>
  `${libraryPath()}/coursebook/${coursebookId}`;

export const unitPath = (coursebookId: string, unitId: number) =>
  `${coursebookPath(coursebookId)}/unit/${unitId}`;

export const editorPath = (coursebookId: string, unitId: number, exerciseId: string) =>
  `${unitPath(coursebookId, unitId)}/exercise/${exerciseId}`;

export const newExercisePath = (coursebookId: string, unitId: number, parentExerciseId?: string) =>
  `${editorPath(coursebookId, unitId, 'new')}${parentExerciseId ? '/' + parentExerciseId : ''}`;

export const unselectedSectionPath = (coursebookId: string, unitId: number) =>
  `${unitPath(coursebookId, unitId)}/section`;

export const selectedSectionPath = (
  coursebookId: string,
  unitId: number,
  coursebookSectionId: number
) => `${unselectedSectionPath(coursebookId, unitId)}/${coursebookSectionId}`;

export const introPath = (coursebookId: string, unitId: number) =>
  `${unitPath(coursebookId, unitId)}/intro`;
