import {type Action} from 'redux';

import {DELETE_DRAFT_EXERCISE, EXERCISE_WAS_CHANGED} from '../actionTypes/xdraftexercises';
import {type XExerciseProperties} from '../widgets/interface';
import {type ChangeDraftExercisesAction, type DeleteDraftExerciseAction} from './interface';

const changeDraftExercises = (exercise: XExerciseProperties): ChangeDraftExercisesAction => ({
  type: EXERCISE_WAS_CHANGED,
  payload: {exercise}
});

export const deleteDraftExercise = (id: string): DeleteDraftExerciseAction => ({
  type: DELETE_DRAFT_EXERCISE,
  payload: {id}
});

export function draftExercisesActionCreator(exercise: XExerciseProperties, action: Action) {
  if (action.type === DELETE_DRAFT_EXERCISE) {
    return deleteDraftExercise(`${exercise.id}`);
  }

  return changeDraftExercises(exercise);
}
