import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {push} from 'store/router';
import {type AppState, type CoursebookRequestError, type Cover} from 'store/interface';
import {type ToggleElementAction} from 'common/interface';
import {type ServerCoursebook} from 'components/CoursebookLibrary/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

import {
  CONFIRM_REDIRECT,
  DECLINE_REDIRECT,
  EDIT_COURSEBOOK_COVER,
  RESET,
  SET_COURSEBOOK_COVER,
  SET_COURSEBOOK_ERROR,
  SET_COURSEBOOK_INFO,
  SET_UPDATING_COURSEBOOK,
  TOGGLE_DETAILED_COURSEBOOK_INFO
} from './actionTypes';

export const toggleDetailedCoursebookInfo = (show: boolean): ToggleElementAction => ({
  type: TOGGLE_DETAILED_COURSEBOOK_INFO,
  show
});

export const editCoursebookCover = (
  id: string,
  title: string,
  coverId: number | null
): AxiosRequestAction => ({
  type: EDIT_COURSEBOOK_COVER,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `/v2/coursebook/${id}`,
      data: {
        title,
        coverId
      }
    }
  }
});

export interface SetCoursebookCoverAction extends Action {
  cover?: Cover;
}

export const setCoursebookCover = (cover?: Cover): SetCoursebookCoverAction => ({
  type: SET_COURSEBOOK_COVER,
  cover
});

export interface SetCoursebookInfoAction extends Action {
  coursebook: ServerCoursebook;
}

export const setCoursebookInfo = (coursebook: ServerCoursebook): SetCoursebookInfoAction => ({
  type: SET_COURSEBOOK_INFO,
  coursebook
});

export interface SetCoursebookErrorAction extends Action {
  error: CoursebookRequestError;
}

export const setCoursebookError = (error: CoursebookRequestError): SetCoursebookErrorAction => ({
  type: SET_COURSEBOOK_ERROR,
  error
});

export const reset = () => ({
  type: RESET
});

export interface ConfirmRedirectAction extends Action {
  path: string;
}

const confirmRedirect = (path: string): ConfirmRedirectAction => ({
  type: CONFIRM_REDIRECT,
  path
});

export const declineRedirect = (): Action => ({
  type: DECLINE_REDIRECT
});

export const redirectFromUnitPage =
  (path: string) => (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
    const unitState = getState().coursebookPage!.unit;
    const exercisesHistoryNonEmpty = unitState && (unitState.hasUndos || unitState.hasRedos);
    const contentsState = getState().coursebookPage!.sections.unitSectionContents;
    const contentsHistoryNonEmpty = contentsState && contentsState.valueChanged;

    if (exercisesHistoryNonEmpty || contentsHistoryNonEmpty) {
      dispatch(confirmRedirect(path));
    } else {
      dispatch(push(path));
    }
  };

export interface SetUpdatingCoursebookAction extends Action {
  isUpdating: boolean;
}

export const setUpdatingCoursebook = (isUpdating: boolean): SetUpdatingCoursebookAction => ({
  type: SET_UPDATING_COURSEBOOK,
  isUpdating
});
