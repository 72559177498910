import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';

const prefix = 'XEDITOR/';

export const CHANGE_TAB = prefix + 'CHANGE_TAB';
export const CHANGE_STAGE = prefix + 'CHANGE_STAGE';
export const VALIDATION_ERRORS = prefix + 'VALIDATION_ERRORS';
export const SET_WIDGET_ERROR = prefix + 'SET_WIDGET_ERROR';
export const RESET = prefix + 'RESET';
export const REQUEST_COURSEBOOK_UNIT_EXERCISE = prefix + 'REQUEST_COURSEBOOK_UNIT_EXERCISE';
export const REQUEST_COURSEBOOK_UNIT_EXERCISE_SUCCESS =
  REQUEST_COURSEBOOK_UNIT_EXERCISE + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_COURSEBOOK_UNIT_EXERCISE_FAIL =
  REQUEST_COURSEBOOK_UNIT_EXERCISE + AXIOS_ERROR_POSTFIX;

export const RESET_LOADING = prefix + 'RESET_LOADING';

export const REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE =
  prefix + 'REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE';
export const REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE_SUCCESS =
  REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE + AXIOS_SUCCESS_POSTFIX;

export const REQUEST_COURSEBOOK_UNIT = prefix + 'REQUEST_COURSEBOOK_UNIT';
export const REQUEST_COURSEBOOK_UNIT_SUCCESS = REQUEST_COURSEBOOK_UNIT + AXIOS_SUCCESS_POSTFIX;

export const CREATE_CATEGORY = prefix + 'CREATE_CATEGORY';
export const DELETE_CATEGORY = prefix + 'DELETE_CATEGORY';
export const RENAME_CATEGORY = prefix + 'RENAME_CATEGORY';
export const RENAME_CATEGORY_SUCCESS = RENAME_CATEGORY + AXIOS_SUCCESS_POSTFIX;
export const RENAME_CATEGORY_FAIL = RENAME_CATEGORY + AXIOS_ERROR_POSTFIX;

export const REQUEST_CATEGORIES = prefix + 'REQUEST_CATEGORIES';
export const REQUEST_CATEGORIES_SUCCESS = REQUEST_CATEGORIES + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_CATEGORIES_FAIL = REQUEST_CATEGORIES + AXIOS_ERROR_POSTFIX;

export const REQUEST_LEVELS = prefix + 'REQUEST_LEVELS';
export const REQUEST_LEVELS_SUCCESS = REQUEST_LEVELS + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_LEVELS_FAIL = REQUEST_LEVELS + AXIOS_ERROR_POSTFIX;

export const REQUEST_GRAMMAR = prefix + 'REQUEST_GRAMMAR';
export const REQUEST_GRAMMAR_SUCCESS = REQUEST_GRAMMAR + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_GRAMMAR_FAIL = REQUEST_GRAMMAR + AXIOS_ERROR_POSTFIX;
export const SELECT_GRAMMAR = prefix + 'SELECT_GRAMMAR';

export const REQUEST_SAVE_COURSEBOOK_UNIT_EXERCISE =
  prefix + 'REQUEST_SAVE_COURSEBOOK_UNIT_EXERCISE';

export const COURSEBOOK_UNIT_EXERCISE_NOT_FOUND = prefix + 'COURSEBOOK_UNIT_EXERCISE_NOT_FOUND';
export const COURSEBOOK_UNIT_EXERCISE_CRITICAL_ERROR =
  prefix + 'COURSEBOOK_UNIT_EXERCISE_CRITICAL_ERROR';

export const COPY_WIDGET = prefix + 'COPY_WIDGET';
export const CLEAR_WIDGET_CLIPBOARD = prefix + 'CLEAR_WIDGET_CLIPBOARD';

export const COPY_UNIT = prefix + 'COPY_UNIT';
export const CLEAR_UNITS_CLIPBOARD = prefix + 'CLEAR_UNIT_CLIPBOARD';

export const COPY_EXERCISE = prefix + 'COPY_EXERCISE';
export const CLEAR_EXERCISES_CLIPBOARD = prefix + 'CLEAR_EXERCISE_CLIPBOARD';

export const SET_UNIT_EXERCISE = prefix + 'SET_UNIT_EXERCISE';

export const RESET_WIDGET_ERRORS = prefix + 'RESET_WIDGET_ERRORS';
export const RESET_MEDIA_SOURCES_ERRORS = prefix + 'RESET_MEDIA_SOURCES_ERRORS';
