import {type List, type Map} from 'immutable';

import {type ExerciseCategory, type UserState} from 'store/interface';
import {type ExerciseJSON, type GrammarExerciseOptions} from 'store/exercise/player/interface';
import {type XPreviewState} from 'store/exercise/player/preview/interface';
import {type DataMap, type DataJSON} from 'components/Slate/interface';

import type XWizardRecord from './widgets/XWizardRecord';
import {type XExerciseProperties, type XDraftExercisesMap} from './widgets/interface';
import {type CoursebookUnitExerciseResponse} from './actions/xeditor';
import {type ToggleElementAction} from '../../../common/interface';

export type XExerciseErrors = DataMap<XErrors>;

interface MediaSourceError {
  mediaSourceId: string;
  message: string;
  errors: string[];
}

export interface XWidgetError {
  widgetId: string;
  message: string;
  errors: string[];
  meta: Record<string, unknown>;
}

type XWidgetErrors = Map<string, XWidgetError>;

export type XMediaSourcesErrors = Map<string, MediaSourceError>;

interface XErrors extends DataJSON {
  title: List<string>;
  widgets: XWidgetErrors;
  mediaSources: XMediaSourcesErrors;
}

export interface XEditorState {
  xwizard?: XWizardRecord;
  activeTab: XEditorTab;
  xexercise: XExerciseProperties;
  draftExercises: XDraftExercisesMap;
  xpreview?: XPreviewState;
  stage: XEditorStage;
  errors: XExerciseErrors;
  categories: ExerciseCategory[];
  levels: Array<{id: number; title: string}>;
  grammar?: GrammarExerciseOptions;
  // coursebook and unit
  loading?: boolean;
  loadingError?: 'not-found' | 'critical';
  unitExercise?: XEditorUnitExercise;
}

export interface CoursebookUnitShort {
  id: number;
  title: string;
  coursebook: {
    id: string;
    title: string;
    languageLevelIds?: number[];
  };
}

export interface XEditorUnitExercise {
  pageNumber?: number;
  ordinal?: number;
  parentExerciseId: string | null;
  exerciseId?: string;
  parentUnitExercise?: {
    pageNumber?: number;
    ordinal?: number;
  };
  unit: CoursebookUnitShort;
}

export interface XEditorStateProps {
  user: UserState;
  unitExercise?: XEditorUnitExercise;
  loading?: boolean;
  loadingError?: 'not-found' | 'critical';
  xexercise?: XExerciseProperties;
  isNew: boolean;
  isCopy: boolean;
  draft: XExerciseProperties;
}

export interface XEditorDispatchProps {
  loadExercise: (
    coursebookId: string,
    unitId: number,
    exerciseId?: string,
    preventDispatch?: boolean
  ) => Promise<CoursebookUnitExerciseResponse | ExerciseJSON | undefined>;
  resetXEditor: () => void;
  resetLoading: () => void;
  requestExerciseSuccess: (data: CoursebookUnitExerciseResponse) => void;
  openWizard: () => ToggleElementAction;
  setXExercise: (exercise: ExerciseJSON) => void;
  setUnitExercise: (unit: XEditorUnitExercise) => void;
  deleteDraft: (id: string) => void;
}

export type XEditorUrlParams = {
  coursebookId: string;
  unitId: string;
  exerciseId?: string;
  mainExerciseId?: string;
};

export type XEditorNewUrlParams = {
  coursebookId: string;
  unitId: string;
  mainExerciseId?: string;
};

export enum XEditorTab {
  EDITOR = 'editor',
  PREVIEW = 'preview'
}

export enum XEditorStage {
  EDITING = 'editing',
  BLOCK_EDITING = 'block_editing'
}
