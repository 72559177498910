import {type Coursebook} from 'store/interface';
import {CoursebookStatus, CoursebookType} from 'common/enums';

import {type ServerCoursebook} from './interface';

export function serverCoursebookToClientCoursebook(c: ServerCoursebook): Coursebook {
  const {
    id,
    title,
    courses,
    cover,
    author,
    languageLevels,
    overview,
    authorOriginal,
    hasGrammar,
    selfCheckEnabled
  } = c;
  return {
    id,
    title,
    type: c.author.profile.accessLevel === 4 ? CoursebookType.COMMON : CoursebookType.PROPRIETARY,
    status: c.publishedAt ? CoursebookStatus.PUBLISHED : CoursebookStatus.HIDDEN,
    courses: courses || [],
    cover,
    levels: languageLevels || [],
    author,
    originalAuthor: authorOriginal,
    overview,
    hasGrammar,
    selfCheckEnabled
  };
}
