import {type ValueJSON} from '@englex/slate';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';

import {
  REQUEST_COURSEBOOK_CONTENTS_BY_SECTION,
  REQUEST_COURSEBOOK_CONTENTS_BY_UNIT,
  REQUEST_COURSEBOOK_INSTANCE_CONTENTS_BY_SECTION,
  REQUEST_COURSEBOOK_INSTANCE_CONTENTS_BY_UNIT
} from './actionTypes';
import {REQUEST_COURSEBOOK_SECTIONS} from '../CoursebookLibrary/actions/actionTypes';

export interface ServerContentsItem {
  unitId: number;
  coursebookSectionId: number;
  description: ValueJSON;
}

export type GetContentsBySectionResponse = AxiosResponseAction<ServerContentsItem[]>;

export const requestCoursebookContentsBySection = (
  coursebookId: string,
  coursebookSectionId: number
): AxiosRequestAction => ({
  type: REQUEST_COURSEBOOK_CONTENTS_BY_SECTION,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/coursebook/${coursebookId}/section/${coursebookSectionId}/unit`
    }
  }
});

export const requestCoursebookInstanceContentsBySection = (
  coursebookInstanceId: string,
  coursebookSectionId: number
): AxiosRequestAction => ({
  type: REQUEST_COURSEBOOK_INSTANCE_CONTENTS_BY_SECTION,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/coursebook-instance/${coursebookInstanceId}/section/${coursebookSectionId}/unit`
    }
  }
});

export const requestCoursebookContentsByUnit = (
  coursebookId: string,
  unitId: number
): AxiosRequestAction => ({
  type: REQUEST_COURSEBOOK_CONTENTS_BY_UNIT,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/coursebook/${coursebookId}/unit/${unitId}/section`
    }
  }
});

export const requestCoursebookInstanceContentsByUnit = (
  coursebookInstanceId: string,
  unitInstanceId: number
): AxiosRequestAction => ({
  type: REQUEST_COURSEBOOK_INSTANCE_CONTENTS_BY_UNIT,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/coursebook-instance/${coursebookInstanceId}/unit/${unitInstanceId}/section`
    }
  }
});

export const requestCoursebookSections = (coursebookId: string): AxiosRequestAction => ({
  type: REQUEST_COURSEBOOK_SECTIONS,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/coursebook/${coursebookId}/section`
    }
  }
});
