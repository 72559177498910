import React from 'react';
import {type Action} from 'redux';

import {type CoursebookSection, type CoursebookUnit, type UnitInstance} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';

import {type CancellablePromise, makeCancellable} from '../../helpers/cancellablePromise';
import CoursebookContentsViewerView from './View';
import {type ViewContentsModalTab} from './interface';
import {type LoadCoursebookDataResponseAction} from '../CoursebookLibrary/actions/action';

interface OwnProps {
  selectedTab: ViewContentsModalTab;
  coursebookId: string;
  coursebookInstanceId?: string;
}

interface DispatchProps {
  loadCoursebookUnits: (coursebookId: string) => Promise<Action>;
  loadCoursebookSections: (coursebookId: string) => Promise<Action>;
  requestContentsBySection: (coursebookId: string, sectionId: number) => Promise<Action>;
  requestContentsByUnit: (coursebookId: string, unitId: number) => Promise<Action>;
}

interface Props extends OwnProps, DispatchProps {}

interface State {
  units?: CoursebookUnit[] | UnitInstance[];
  sections?: CoursebookSection[];
  selectedUnitId?: number;
  selectedSectionId?: number;
  requestFailed?: boolean;
  sidebarPulledOut: boolean;
}

class ContentsViewer extends React.Component<Props, State> {
  public state: State = {sidebarPulledOut: false};

  private unitsRequest: CancellablePromise;
  private sectionsRequest: CancellablePromise;

  public componentWillUnmount(): void {
    this.cancelRequestsIfNeeded();
  }

  public componentDidMount(): void {
    this.loadCoursebookSections();
    this.loadCoursebookUnits();
  }

  public get actualUnits() {
    if (this.isUnitInstances(this.state.units)) {
      return (
        this.state.units &&
        this.state.units.map(unitInstance => ({
          ...unitInstance.unit,
          ordinal: unitInstance.ordinal
        }))
      );
    }

    return this.state.units;
  }

  public render() {
    return (
      <CoursebookContentsViewerView
        reset={this.reset}
        units={this.actualUnits}
        sections={this.state.sections}
        selectedTab={this.props.selectedTab}
        requestContentsBySection={this.requestContentsBySection}
        requestContentsByUnit={this.requestContentsByUnit}
        selectedUnitId={this.state.selectedUnitId}
        selectedSectionId={this.state.selectedSectionId}
        requestFailed={this.state.requestFailed}
        handleRequestFailed={this.handleRequestFail}
        selectUnit={this.selectUnit}
        selectSection={this.selectSection}
        sidebarPulledOut={this.state.sidebarPulledOut}
        toggleSidebar={this.toggleSidebar}
      />
    );
  }

  private requestContentsByUnit = (unitId: number) => {
    if (this.props.coursebookInstanceId) {
      const unitInstanceId = (this.state.units as UnitInstance[])!.find(
        ui => ui.unit.id === unitId
      )!.id;
      return this.props.requestContentsByUnit(this.props.coursebookInstanceId, unitInstanceId);
    }
    return this.props.requestContentsByUnit(this.props.coursebookId, unitId);
  };

  private reset = () => {
    this.cancelRequestsIfNeeded();
    this.setState({
      units: undefined,
      sections: undefined,
      selectedSectionId: undefined,
      selectedUnitId: undefined,
      requestFailed: undefined
    });
    this.loadCoursebookSections();
    this.loadCoursebookUnits();
  };

  private cancelRequestsIfNeeded = () => {
    const {unitsRequest, sectionsRequest} = this;

    unitsRequest?.cancel();
    sectionsRequest?.cancel();
  };

  private selectUnit = (unitId: number) => {
    this.setState({selectedUnitId: unitId});
    this.closeSidebar();
  };

  private selectSection = (sectionId: number) => {
    this.setState({selectedSectionId: sectionId});
    this.closeSidebar();
  };

  private loadCoursebookUnits = () => {
    const courseId = this.props.coursebookInstanceId || this.props.coursebookId;

    this.unitsRequest = makeCancellable(
      this.props.loadCoursebookUnits(courseId),
      this.handleUnitsLoaded,
      this.handleRequestFail
    );
  };

  private handleUnitsLoaded = (
    action: LoadCoursebookDataResponseAction | AxiosResponseAction<UnitInstance[]>
  ) => {
    const units = this.isUnitInstancesAction(action)
      ? action.payload.data
      : action.payload.data.units;
    const firstUnitId = this.isUnitInstances(units) ? units[0].unit.id : units[0].id;

    this.setState({units});
    this.setState({selectedUnitId: firstUnitId});
  };

  private loadCoursebookSections = () => {
    this.sectionsRequest = makeCancellable(
      this.props.loadCoursebookSections(this.props.coursebookId),
      this.handleSectionsLoaded,
      this.handleRequestFail
    );
  };

  private handleSectionsLoaded = (action: AxiosResponseAction<CoursebookSection[]>) => {
    const sections = action.payload.data;
    const firstSectionId = sections[0].id;

    this.setState({sections});
    this.setState({selectedSectionId: firstSectionId});
  };

  private handleRequestFail = () => {
    this.setState({requestFailed: true});
  };

  private requestContentsBySection = (sectionId: number) => {
    const {coursebookInstanceId, coursebookId, requestContentsBySection} = this.props;
    const courseId = coursebookInstanceId || coursebookId;

    return requestContentsBySection(courseId, sectionId);
  };

  private toggleSidebar = () => {
    this.setState({sidebarPulledOut: !this.state.sidebarPulledOut});
  };

  private closeSidebar = () => {
    this.setState({sidebarPulledOut: false});
  };

  private isUnitInstances = (
    units?: CoursebookUnit[] | UnitInstance[]
  ): units is UnitInstance[] => {
    return !!this.props.coursebookInstanceId;
  };

  private isUnitInstancesAction = (
    action: LoadCoursebookDataResponseAction | AxiosResponseAction<UnitInstance[]>
  ): action is AxiosResponseAction<UnitInstance[]> => {
    return !!this.props.coursebookInstanceId;
  };
}

export default ContentsViewer;
