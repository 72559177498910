import React from 'react';
import {FormattedMessage} from 'react-intl';
import {type Action} from 'redux';

import {type CoursebookSection, type CoursebookUnit} from 'store/interface';

import {ViewContentsModalTab} from './interface';
import ModalError from '../ModalError';
import Loader from '../Loader';
import ContentsBySections from './ContentsBySections';
import ContentsByUnits from './ContentsByUnits';

interface Props {
  reset: () => void;
  units?: CoursebookUnit[];
  sections?: CoursebookSection[];
  selectedTab: ViewContentsModalTab;
  requestContentsBySection: (sectionId: number) => Promise<Action>;
  requestContentsByUnit: (unitId: number) => Promise<Action>;
  selectedUnitId?: number;
  selectedSectionId?: number;
  requestFailed?: boolean;
  sidebarPulledOut: boolean;
  selectSection: (id: number) => void;
  selectUnit: (id: number) => void;
  handleRequestFailed: () => void;
  toggleSidebar: () => void;
}

class CoursebookContentsViewerView extends React.Component<Props> {
  public render() {
    if (this.props.requestFailed) {
      return (
        <ModalError
          reload={this.props.reset}
          errorTitle={<FormattedMessage id="ViewCoursebookContentsModal.RequestErrorTitle" />}
          errorText={<FormattedMessage id="LibraryError.Description" />}
        />
      );
    }
    if (!this.props.sections || !this.props.units) {
      return <Loader />;
    }
    if (this.props.selectedTab === ViewContentsModalTab.SECTIONS) {
      return (
        <ContentsBySections
          coursebookSections={this.props.sections}
          coursebookUnits={this.props.units}
          selectedSectionId={this.props.selectedSectionId}
          selectSection={this.props.selectSection}
          handleRequestError={this.props.handleRequestFailed}
          requestContents={this.props.requestContentsBySection}
          sidebarPulledOut={this.props.sidebarPulledOut}
          toggleSidebar={this.props.toggleSidebar}
        />
      );
    }
    if (this.props.selectedTab === ViewContentsModalTab.UNITS) {
      return (
        <ContentsByUnits
          coursebookUnits={this.props.units}
          coursebookSections={this.props.sections}
          selectUnit={this.props.selectUnit}
          selectedUnitId={this.props.selectedUnitId}
          requestContents={this.props.requestContentsByUnit}
          handleRequestError={this.props.handleRequestFailed}
          sidebarPulledOut={this.props.sidebarPulledOut}
          toggleSidebar={this.props.toggleSidebar}
        />
      );
    }
    return null;
  }
}

export default CoursebookContentsViewerView;
