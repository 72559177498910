import React from 'react';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {connect} from 'react-redux';

import * as toastr from 'components/toastr';
import {coursebookLibraryPageSize} from 'config/static';
import {type AppState, type Coursebook, type CoursebookFilter} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';

import Loader from '../../../components/Loader';
import InfiniteScroll from '../../../components/InfiniteScroll';
import {type CancellablePromise, makeCancellable} from '../../../helpers/cancellablePromise';
import {
  addToCoursebookList,
  clearCoursebooksList,
  type RequestCoursebookResponseAction,
  requestCoursebooks,
  setCoursebookList,
  setLibraryUpdating,
  setTotalCoursebooksCount,
  deleteCoursebook
} from '../actions/action';
import {coursebookLibraryMessages} from '../messages';
import {commonMessages} from '../../../i18n/commonMessages';
import {type CoursebookItemComponentProps, type ServerCoursebook} from '../interface';

import './CoursebookLibraryList.scss';

interface OwnProps extends WrappedComponentProps {
  totalCount?: number;
  coursebooks?: Coursebook[];
  filter: CoursebookFilter;
  setLibraryError: () => void;
  getEditSuccessCallback?: (callback: () => void) => void;
  coursebookItemComponent:
    | React.ComponentClass<CoursebookItemComponentProps>
    | React.FC<CoursebookItemComponentProps>;
}

interface StateProps {
  totalCount?: number;
  coursebooks?: Coursebook[];
}

interface DispatchProps {
  requestCoursebooks: (
    filter: CoursebookFilter,
    pageNumber: number,
    pageSize: number
  ) => Promise<RequestCoursebookResponseAction>;
  setLibraryUpdating: (isUpdating: boolean) => void;
  setCoursebookList: (list: ServerCoursebook[]) => void;
  clearCoursebooksList: () => void;
  setTotalCoursebooksCount: (totalCount: number) => void;
  deleteCoursebook: (id: string) => Promise<AxiosResponseAction<{}>>;
  addToCoursebookList: (list: ServerCoursebook[]) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

interface State {
  allCoursebooksLoaded?: boolean;
}

class CoursebookLibraryList extends React.Component<Props, State> {
  public state: State = {};
  private infiniteScroll: InfiniteScroll | null;
  private deleteCoursebookRequest?: CancellablePromise;
  private coursebookListRequest?: CancellablePromise;

  public componentWillUnmount(): void {
    if (this.deleteCoursebookRequest) {
      this.deleteCoursebookRequest.cancel();
    }
    if (this.coursebookListRequest) {
      this.coursebookListRequest.cancel();
    }
  }

  public componentDidMount() {
    this.loadCoursebookListPage(1);
    if (this.props.getEditSuccessCallback) {
      this.props.getEditSuccessCallback(this.reloadAfterEdit);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const prevFilterKeys = Object.keys(prevProps.filter);
    const filterChanged = prevFilterKeys.find(
      key => String(this.props.filter[key]) !== String(prevProps.filter[key])
    );

    if (filterChanged) {
      this.props.clearCoursebooksList();
      this.resetInfiniteScrollPage();
      this.loadCoursebookListPage(1);
    }
  }

  public render() {
    const {coursebooks} = this.props;
    if (!coursebooks) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        <div className="coursebooks-list">
          <Scrollbars autoHide={true}>
            <InfiniteScroll
              loadMore={this.loadCoursebookListPage}
              pageStart={1}
              hasMore={!this.state.allCoursebooksLoaded}
              initialLoad={false}
              useWindow={false}
              loader={<Loader key="1" />}
              threshold={50}
              ref={this.infiniteScrollRef}
              withResizeDetector={true}
            >
              <React.Fragment>
                {!coursebooks.length
                  ? this.renderEmptyLibrary()
                  : [this.renderCount()].concat(coursebooks.map(this.renderCoursebookItem))}
              </React.Fragment>
            </InfiniteScroll>
          </Scrollbars>
        </div>
      </React.Fragment>
    );
  }

  private reloadAfterEdit = () => {
    const listLength = this.props.coursebooks!.length;
    this.reloadCurrentCoursebookList(listLength);
  };

  private reloadCurrentCoursebookList = (listLength: number) => {
    if (this.coursebookListRequest) {
      this.coursebookListRequest.cancel();
    }
    this.coursebookListRequest = makeCancellable(
      this.props.requestCoursebooks(this.props.filter, 1, listLength),
      this.handleCoursebookListReloaded,
      this.props.setLibraryError
    );
  };

  private handleCoursebookListReloaded = (action: RequestCoursebookResponseAction) =>
    this.props.setCoursebookList(action.payload.data);

  private infiniteScrollRef = (el: InfiniteScroll | HTMLElement | null) =>
    (this.infiniteScroll = el as InfiniteScroll);

  private renderCount = () => (
    <div key="total-count" className="total-count">
      {this.renderTotalCount()}
    </div>
  );

  private renderEmptyLibrary = () => {
    return (
      <div className="empty-library">
        <FormattedMessage id="CoursebookLibrary.EmptyLibrary" />
      </div>
    );
  };

  private renderCoursebookItem = (coursebook: Coursebook) => {
    const Component = this.props.coursebookItemComponent;
    return (
      <Component
        coursebook={coursebook}
        key={coursebook.id}
        deleteCoursebook={this.deleteCoursebook}
      />
    );
  };

  private renderTotalCount = () => {
    const {totalCount, filter} = this.props;
    const filterEmpty = Object.values(filter).every(value => value === null);

    if (filterEmpty) {
      return (
        <FormattedMessage id="CoursebookLibrary.TotalCountNoFilter" values={{count: totalCount}} />
      );
    }
    return <FormattedMessage id="CoursebookLibrary.TotalCount" values={{count: totalCount}} />;
  };

  private resetInfiniteScrollPage = () => {
    if (this.infiniteScroll) {
      this.infiniteScroll.pageLoaded = 1;
    }
    if (this.state.allCoursebooksLoaded) {
      this.setState({allCoursebooksLoaded: false});
    }
  };

  private loadCoursebookListPage = (pageNumber: number) => {
    if (this.coursebookListRequest) {
      this.coursebookListRequest.cancel();
    }
    const promise = this.props.requestCoursebooks(
      this.props.filter,
      pageNumber,
      coursebookLibraryPageSize
    );
    this.coursebookListRequest = makeCancellable(
      promise,
      this.handleCoursebookPageLoaded,
      this.props.setLibraryError
    );
    return promise;
  };

  private handleCoursebookPageLoaded = (action: RequestCoursebookResponseAction) => {
    const totalCount = Number(action.payload.headers['x-pagination-total-count']);
    this.props.setTotalCoursebooksCount(totalCount);
    if (action.payload.data.length < coursebookLibraryPageSize) {
      this.setState({allCoursebooksLoaded: true});
    }
    if (this.props.coursebooks) {
      this.props.addToCoursebookList(action.payload.data);
    } else {
      this.props.setCoursebookList(action.payload.data);
    }
  };

  private deleteCoursebook = (id: string) => {
    this.props.setLibraryUpdating(true);
    this.deleteCoursebookRequest = makeCancellable(
      this.props.deleteCoursebook(id),
      this.handleCoursebookDeleted,
      this.handleCoursebookDeleteFailed
    );
  };

  private handleCoursebookDeleted = () => {
    const {
      intl: {formatMessage},
      coursebooks
    } = this.props;
    this.props.setLibraryUpdating(false);
    const listLength = coursebooks!.length;
    this.reloadCurrentCoursebookList(listLength);
    toastr.success('', formatMessage(coursebookLibraryMessages.CoursebookDeletedSuccessToast));
  };

  private handleCoursebookDeleteFailed = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    this.props.setLibraryUpdating(false);
    toastr.error('', formatMessage(commonMessages.DeleteRequestFailed));
  };
}

const mapStateToProps = (state: AppState): StateProps => ({
  coursebooks: state.coursebookLibrary!.coursebooks,
  totalCount: state.coursebookLibrary!.totalCount
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  requestCoursebooks: (filter: CoursebookFilter, pageNumber: number, pageSize: number) =>
    dispatch(requestCoursebooks(filter, pageNumber, pageSize)),
  setLibraryUpdating: (isUpdating: boolean) => dispatch(setLibraryUpdating(isUpdating)),
  setCoursebookList: (list: ServerCoursebook[]) => dispatch(setCoursebookList(list)),
  clearCoursebooksList: () => dispatch(clearCoursebooksList()),
  setTotalCoursebooksCount: (totalCount: number) => dispatch(setTotalCoursebooksCount(totalCount)),
  deleteCoursebook: (id: string) => dispatch(deleteCoursebook(id)),
  addToCoursebookList: (list: ServerCoursebook[]) => dispatch(addToCoursebookList(list))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CoursebookLibraryList));
