import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {type List} from 'immutable';

import {type AppState} from 'store/interface';
import XPreview from 'components/XPreview/XPreview';
import Loader from 'components/Loader';
import PageControls from 'components/PageControls';
import {Pager} from 'components/UnitContents/Pager';
import {useContentsList} from 'components/UnitContents/useContenstList';
import {Contents} from 'components/UnitContents/Contents/Contents';
import GrammarPlayerPage from 'common/GrammarPlayerPage/GrammarPlayerPage';
import {IntroRecord} from 'store/intro/IntroRecord';
import {type UnitContents, type UnitInstanceContents} from 'components/UnitContents/interface';

import ExtraExercisesPreview from './ExtraExercisesPreview';
import {XIntroPreview} from '../../XIntroPreview';
import {Preview, type UnitPreviewProps} from './UnitPreviewTypes';
import {UnitContentItems} from './UnitContentItems';

export const UnitPreview: React.FC<UnitPreviewProps> = ({
  isLoading,
  xpreview,
  changeRole,
  pageNumber,
  pageCount,
  selectPage,
  mainId,
  coursebookId,
  unitId,
  grammarPreview,
  scrollToExerciseId,
  unit
}) => {
  const [preview, setPreview] = useState(Preview.Exercise);

  const intro = useMemo(() => (unit?.intro ? new IntroRecord(unit.intro) : null), [unit]);

  const previewRole = useMemo(() => xpreview?.role, [xpreview]);

  const loaded = useRef(false);

  const contentListOptions = useMemo(
    () => ({
      type: 'original' as 'original',
      unitId,
      coursebookId,
      forStudent: previewRole === 'student'
    }),
    [coursebookId, previewRole, unitId]
  );

  const {
    batchListToggle,
    contents,
    hasNext,
    hasPrev,
    listIsOpened,
    listToggleBatched,
    loading,
    toggleList
  } = useContentsList(pageCount, pageNumber, contentListOptions);

  const grammar = useSelector<AppState, List<{id: number}> | undefined>(
    state => state.unitPreview?.xpreview?.exercises?.find(ex => ex?.id === grammarPreview)?.grammar
  );

  const onStart = useCallback(() => setPreview(Preview.Exercise), []);

  const goToIntro = useCallback(() => setPreview(Preview.Intro), []);

  const onView = useCallback(
    () => (listIsOpened ? batchListToggle() : toggleList()),
    [batchListToggle, listIsOpened, toggleList]
  );

  const onSelectPage = useCallback(
    (page: number) => {
      if (preview === Preview.Intro) {
        setPreview(Preview.Exercise);
      }

      selectPage(page);
    },
    [preview, setPreview, selectPage]
  );

  useEffect(() => {
    if (!loaded.current && unit) {
      loaded.current = true;
      setPreview(unit.intro ? Preview.Intro : Preview.Exercise);
    }
  }, [unit]);

  if (mainId) {
    return <ExtraExercisesPreview coursebookId={coursebookId} unitId={unitId} mainId={mainId} />;
  }

  if (grammarPreview && grammar) {
    return (
      <GrammarPlayerPage
        coursebookId={coursebookId}
        isInUnitPlayer={false}
        grammarFilter={grammar}
        isModal={true}
      />
    );
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : preview === Preview.Intro && intro ? (
        <XIntroPreview intro={intro} unit={unit!} onStart={onStart} onView={onView} />
      ) : (
        <XPreview
          xpreview={xpreview!}
          changeRole={changeRole}
          pageNumber={pageNumber}
          scrollToExerciseId={scrollToExerciseId}
          isModal={true}
        />
      )}

      <Contents
        contents={contents}
        listIsOpened={listIsOpened}
        listToggleBatched={listToggleBatched}
        toggleList={toggleList}
        renderItems={closeSelf => (
          <UnitContentItems
            contents={contents as UnitInstanceContents | UnitContents}
            isIntro={preview === Preview.Intro}
            haveIntro={!!intro}
            onSelectPage={onSelectPage}
            goToIntro={goToIntro}
            closeSelf={closeSelf}
            pageNumber={pageNumber}
          />
        )}
      />

      {preview === Preview.Exercise && (
        <PageControls>
          <Pager
            batchListToggle={batchListToggle}
            currentPage={pageNumber}
            hasNext={hasNext}
            hasPrev={hasPrev}
            listIsOpened={listIsOpened}
            loading={loading}
            pageCount={pageCount}
            selectPage={selectPage}
            toggleList={toggleList}
          />
        </PageControls>
      )}
    </>
  );
};
