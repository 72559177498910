import React from 'react';
import {type Action} from 'redux';
import {Value} from '@englex/slate';

import {type CoursebookSection, type CoursebookUnit} from 'store/interface';
import {BootstrapBreakpoint} from 'config/static';

import ContentsView from './ContentsView';
import ItemsListColumn from './ItemsListColumn';
import {type CancellablePromise, makeCancellable} from '../../helpers/cancellablePromise';
import {type GetContentsBySectionResponse, type ServerContentsItem} from './action';
import {type ContentsItem} from './interface';
import {LibraryListAlignment} from '../CoursebookLibrary/LibraryPageList/constants';
import LibraryPageListEl from '../CoursebookLibrary/LibraryPageList/LibraryPageListEl';
import {
  FloatSidebar,
  FloatSidebarPosition,
  FloatSidebarProvider
} from '../FloatSidebar/FloatSidebar';
import {CollapsedSidebarButton} from '../FloatSidebar/CollapsedSidebarButton';

interface Props {
  coursebookSections: CoursebookSection[];
  coursebookUnits: CoursebookUnit[];
  selectedSectionId?: number;
  sidebarPulledOut: boolean;
  selectSection: (sectionId: number) => void;
  requestContents: (sectionId: number) => Promise<Action>;
  handleRequestError: () => void;
  toggleSidebar: () => void;
}

interface State {
  contents?: ContentsItem[];
}

export default class ContentsBySections extends React.Component<Props, State> {
  public state: State = {};
  private request: CancellablePromise;

  public componentWillUnmount(): void {
    if (this.request) {
      this.request.cancel();
    }
  }

  public componentDidMount(): void {
    if (this.props.selectedSectionId) {
      this.requestContents(this.props.selectedSectionId);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const {selectedSectionId} = this.props;
    if (selectedSectionId && prevProps.selectedSectionId !== selectedSectionId) {
      this.requestContents(selectedSectionId);
    }
  }

  public render() {
    return (
      <React.Fragment>
        <FloatSidebar
          provider={FloatSidebarProvider.State}
          breakpoint={BootstrapBreakpoint.LG}
          position={FloatSidebarPosition.Left}
          sidebarPulledOut={this.props.sidebarPulledOut}
          collapsedButton={(clickHandler, sidebarCollapsed) => (
            <CollapsedSidebarButton
              onClick={this.props.toggleSidebar}
              shouldBeRendered={sidebarCollapsed || false}
            />
          )}
        >
          <ItemsListColumn>
            {this.props.coursebookSections.map(this.renderCoursebookSectionItem)}
          </ItemsListColumn>
        </FloatSidebar>
        <ContentsView contents={this.props.selectedSectionId ? this.state.contents : []} />
      </React.Fragment>
    );
  }

  private renderCoursebookSectionItem = (coursebookSection: CoursebookSection) => {
    return (
      <LibraryPageListEl
        isActive={coursebookSection.id === this.props.selectedSectionId}
        handleClick={() => this.props.selectSection(coursebookSection.id)}
        title={coursebookSection.section.title}
        dropdownButtons={[]}
        alignment={LibraryListAlignment.LEFT}
        renderHandle={() => null}
        key={coursebookSection.id}
      />
    );
  };

  private requestContents = (sectionId: number) => {
    if (this.request) {
      this.request.cancel();
    }
    this.setState({contents: undefined});
    this.request = makeCancellable(
      this.props.requestContents(sectionId),
      this.handleRequestSuccess,
      this.props.handleRequestError
    );
  };

  private handleRequestSuccess = (action: GetContentsBySectionResponse) => {
    const {coursebookUnits} = this.props;
    const contents = action.payload.data.reduce<ContentsItem[]>(
      (result: ContentsItem[], contentsItem: ServerContentsItem) => {
        const coursebookUnit = coursebookUnits.find(unit => unit.id === contentsItem.unitId);
        if (!coursebookUnit) {
          // this can happen if coursebook unit was removed in another tab before modal was opened in this tab
          return result;
        }
        const isRevision = coursebookUnit.isRevision;
        const prefix = !isRevision ? `Unit ${coursebookUnit.ordinal}: ` : '';
        return [
          ...result,
          {
            title: `${prefix}${coursebookUnit.title}`,
            description: Value.fromJSON(contentsItem.description),
            id: contentsItem.unitId
          }
        ];
      },
      []
    );
    this.setState({contents});
  };
}
