import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import './ItemsListColumn.scss';

export default class ItemsListColumn extends React.Component {
  public render() {
    return (
      <div className="view-contents-items-list-column">
        <Scrollbars autoHide={true}>
          {this.props.children}
          <div className="empty-space" />
        </Scrollbars>
      </div>
    );
  }
}
