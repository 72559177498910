import {type Action} from 'redux';

import {type Role} from 'store/interface';

import {CHANGE_ROLE} from './actionTypes';

interface XPreviewAction extends Action {}

export interface XPreviewRoleAction extends XPreviewAction {
  role: Role;
}

export const changePreviewRole = (role: Role): XPreviewRoleAction => {
  return {
    type: CHANGE_ROLE,
    role
  };
};
