import React, {type FC} from 'react';

import XPlayer from 'components/XPlayer';
import {type XPreviewProps} from 'store/exercise/player/preview/interface';

import RoleSwitcher from './RoleSwitcher';

import './XPreview.scss';

const XPreview: FC<XPreviewProps> = ({
  xpreview,
  changeRole,
  pageNumber,
  scrollToExerciseId,
  isModal
}) => {
  return xpreview ? (
    <div className="x-preview">
      <RoleSwitcher changeRole={changeRole} role={xpreview.role} />
      <XPlayer
        exercises={xpreview.exercises}
        page={pageNumber}
        preview={true}
        role={xpreview.role}
        showPreviewExerciseNumber={xpreview.addExerciseNumber}
        scrollToExerciseId={scrollToExerciseId}
        isModal={isModal}
      />
    </div>
  ) : null;
};

export default XPreview;
