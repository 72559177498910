import React from 'react';

import {type UnitContents, type UnitInstanceContents} from 'components/UnitContents/interface';

import {ListItem} from '../../UnitContents/Contents/ListItem';

const introData = {
  title: 'UNIT OVERVIEW',
  exercisesCount: null,
  task: null
};

interface Props {
  contents: UnitInstanceContents | UnitContents;
  isIntro: boolean;
  haveIntro: boolean;
  onSelectPage: (page: number) => void;
  closeSelf: () => void;
  pageNumber?: number;
  goToIntro?: () => void;
}
export const UnitContentItems = ({
  contents,
  isIntro,
  pageNumber,
  haveIntro,
  onSelectPage,
  goToIntro,
  closeSelf
}: Props) => (
  <>
    {haveIntro && (
      <ListItem
        active={isIntro}
        closeParent={closeSelf}
        data={introData}
        index={0}
        key={0}
        selectPage={goToIntro || onSelectPage}
      />
    )}

    {Object.keys(contents.contents).map(key => (
      <ListItem
        active={!isIntro && Number(key) === pageNumber}
        closeParent={closeSelf}
        data={contents?.contents[key]}
        index={Number(key)}
        key={key}
        selectPage={onSelectPage}
      />
    ))}
  </>
);
