import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';

import CoursebookLibraryHeader from '../components/CoursebookLibraryHeader';
import {closeExtendedSearchPanel, openExtendedSearchPanel} from '../actions/action';
import {
  type CoursebookLibraryHeaderStateProps,
  type CoursebookLibraryHeaderDispatchProps
} from '../components/interface';

const mapStateToProps = (state: AppState): CoursebookLibraryHeaderStateProps => {
  const {extendedSearchPanelOpen} = state.coursebookLibrary!;

  return {
    role: state.user.role,
    extendedSearchPanelOpen
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>
): CoursebookLibraryHeaderDispatchProps => ({
  openExtendedSearchPanel: () => dispatch(openExtendedSearchPanel()),
  closeExtendedSearchPanel: () => dispatch(closeExtendedSearchPanel())
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursebookLibraryHeader);
