import React from 'react';
import {type WrappedComponentProps} from 'react-intl';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import {BooleanEnum} from 'common/enums';

import {coursebookLibraryMessages} from '../../messages';
import Icon from '../../../Icon';
import {type SelectCallback} from '../../../interface';

interface Props extends WrappedComponentProps {
  published: BooleanEnum | null;
  changeCoursebookPublishedFilter: (published: BooleanEnum | null) => void;
}

export default class StatusDropdown extends React.Component<Props> {
  public render() {
    const {
      intl: {formatMessage},
      published
    } = this.props;
    const anyMsg = formatMessage(coursebookLibraryMessages.DropdownValueAny);
    const hiddenMsg = formatMessage(coursebookLibraryMessages.StatusHidden);
    const publishedMsg = formatMessage(coursebookLibraryMessages.StatusPublished);
    const dropdownTitleText =
      (published === BooleanEnum.TRUE && publishedMsg) ||
      (published === BooleanEnum.FALSE && hiddenMsg) ||
      anyMsg;
    return (
      <DropdownButton
        id="coursebook-extended-search-status"
        title={
          <React.Fragment>
            <span key="1">{dropdownTitleText}</span>
            <Icon key="2" name="angle-down" className="custom-caret" />
          </React.Fragment>
        }
        onSelect={this.changeFilter as SelectCallback}
        className="dropdown-toggle"
        noCaret={true}
      >
        <MenuItem active={published === null} eventKey={null}>
          {anyMsg}
        </MenuItem>
        <MenuItem active={published === BooleanEnum.FALSE} eventKey={BooleanEnum.FALSE}>
          {hiddenMsg}
        </MenuItem>
        <MenuItem active={published === BooleanEnum.TRUE} eventKey={BooleanEnum.TRUE}>
          {publishedMsg}
        </MenuItem>
      </DropdownButton>
    );
  }

  private changeFilter = (eventKey: BooleanEnum | null) => {
    // pass this function as callback so that event doesn't get passed as second call param
    this.props.changeCoursebookPublishedFilter(eventKey);
  };
}
