import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {captureException} from '@sentry/react';

import {
  type AppState,
  type ExerciseCategory,
  type LanguageLevel,
  type UserState
} from 'store/interface';
import {
  type AxiosRequestAction,
  type AxiosRequestError,
  type AxiosResponseAction,
  isAxiosError
} from 'services/axios/interface';
import {
  type ExerciseJSON,
  type GrammarExerciseOptions,
  type WidgetJSON
} from 'store/exercise/player/interface';
import {genKey} from 'components/Slate/utils';

import {type CoursebookUnitShort, type XEditorTab, type XEditorUnitExercise} from '../interface';
import {
  CHANGE_TAB,
  CLEAR_EXERCISES_CLIPBOARD,
  CLEAR_UNITS_CLIPBOARD,
  CLEAR_WIDGET_CLIPBOARD,
  COPY_EXERCISE,
  COPY_UNIT,
  COPY_WIDGET,
  COURSEBOOK_UNIT_EXERCISE_CRITICAL_ERROR,
  COURSEBOOK_UNIT_EXERCISE_NOT_FOUND,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  RENAME_CATEGORY,
  REQUEST_CATEGORIES,
  REQUEST_COURSEBOOK_UNIT,
  REQUEST_COURSEBOOK_UNIT_EXERCISE,
  REQUEST_COURSEBOOK_UNIT_EXERCISE_SUCCESS,
  REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE,
  REQUEST_GRAMMAR,
  REQUEST_LEVELS,
  RESET_LOADING,
  RESET_MEDIA_SOURCES_ERRORS,
  RESET_WIDGET_ERRORS,
  SELECT_GRAMMAR,
  SET_UNIT_EXERCISE,
  SET_WIDGET_ERROR
} from '../actionTypes/xeditor';
import {newXExercise} from './xexercise';
import {
  type GrammarSelectAction,
  type XEditorChangeTabAction,
  type XEditorWidgetErrorAction
} from './interface';

export function changeTab(tab: XEditorTab): XEditorChangeTabAction {
  return {
    type: CHANGE_TAB,
    tab
  };
}

export function setWidgetError(
  xwidgetId: string,
  message: string,
  meta?: Record<string, unknown>
): XEditorWidgetErrorAction {
  return {
    type: SET_WIDGET_ERROR,
    xwidgetId,
    message,
    meta
  };
}

const exerciseNotFound: Action = {
  type: COURSEBOOK_UNIT_EXERCISE_NOT_FOUND
};

const exerciseLoadCriticalError: Action = {
  type: COURSEBOOK_UNIT_EXERCISE_CRITICAL_ERROR
};

export const loadExercise =
  (coursebookId: string, unitId: number, exerciseId: string, preventDispatch: boolean) =>
  async (dispatch: Dispatch<AxiosRequestAction | Action, AppState>, getState: () => AppState) => {
    try {
      await dispatch(requestCategories());
      await dispatch(requestLevels());
      const exerciseAction: AxiosResponseAction<CoursebookUnitExerciseResponse> = await dispatch(
        requestExercise(coursebookId, unitId, exerciseId, preventDispatch)
      );
      await dispatch(requestGrammar(coursebookId, false));
      const {unitExercise} = getState().xeditor!;
      if (unitExercise && unitExercise.parentExerciseId) {
        await dispatch(requestMainExercise(coursebookId, unitId, unitExercise.parentExerciseId));
      }

      return exerciseAction.payload.data;
    } catch (err) {
      const e: AxiosRequestError = err;
      if (e.error && e.error.response && [400, 404].includes(e.error.response.status)) {
        dispatch(exerciseNotFound);
      } else {
        dispatch(exerciseLoadCriticalError);
        if (!isAxiosError(e)) {
          captureException(e);
        }
      }

      return undefined;
    }
  };

const bootstrapNewExercise = ({id, role, profile}: UserState) => {
  const {first_name: firstName, last_name: lastName, email} = profile!;
  return newXExercise({
    title: '',
    isNew: true,
    widgets: [],
    meta: {
      categories: [],
      levels: []
    },
    id: null,
    ownership: {
      author: {
        role,
        id,
        profile: {
          firstName,
          lastName,
          email
        }
      }
    }
  });
};

const bootstrapExerciseCopy = (
  {id, role, profile}: UserState,
  copiedExercise: ExerciseJSON,
  grammar?: GrammarExerciseOptions
) => {
  const {first_name: firstName, last_name: lastName, email} = profile!;
  return newXExercise({
    title: copiedExercise.title,
    widgets: copiedExercise.widgets.map(w => {
      return {...w, id: genKey(), version: null};
    }),
    grammar: grammar || [],
    meta: {
      categories: copiedExercise.meta?.categories || [],
      levels: copiedExercise.meta?.levels || []
    },
    id: null,
    ownership: {
      author: {
        role,
        id: id!,
        profile: {
          firstName,
          lastName,
          email
        }
      }
    },
    mediaSources: copiedExercise.mediaSources
  });
};

export const newExercise =
  (
    coursebookId: string,
    unitId: number,
    forceRenew: boolean,
    mainExerciseId?: string,
    copiedExerciseId?: string
  ) =>
  async (
    dispatch: Dispatch<AxiosRequestAction | Action, AppState>,
    getState: () => AppState
  ): Promise<ExerciseJSON | undefined> => {
    let action;
    let exercise;
    const state: AppState = getState();
    const {xexercise} = state.xeditor!;
    try {
      await dispatch(requestCategories());
      await dispatch<AxiosResponseAction<RequestLevelsResponseAction>>(requestLevels());
      await dispatch(requestGrammar(coursebookId, false));

      if (!xexercise.widgets.size || forceRenew) {
        if (!copiedExerciseId) {
          action = await dispatch(bootstrapNewExercise(state.user));
          exercise = action.xexercise;
        } else {
          action = await dispatch<AxiosResponseAction<CoursebookUnitExerciseResponse>>(
            requestExercise(coursebookId, unitId, copiedExerciseId, true)
          );
          const {data} = action.payload;
          action = dispatch(bootstrapExerciseCopy(state.user, data.exercise!, data.grammar));
          exercise = action.xexercise;
        }
      }
      await dispatch(
        mainExerciseId
          ? requestMainExercise(coursebookId, unitId, mainExerciseId, !copiedExerciseId)
          : requestUnit(coursebookId, unitId, !copiedExerciseId)
      );

      return exercise;
    } catch (e) {
      if ([400, 404].includes(e.error?.response?.status)) {
        dispatch(exerciseNotFound);
      } else {
        dispatch(exerciseLoadCriticalError);
        if (!isAxiosError(e)) {
          captureException(e);
        }
      }
      return undefined;
    }
  };

const requestExercise: (
  coursebookId: string,
  unitId: number,
  exerciseId: string,
  preventDispatch?: boolean
) => AxiosRequestAction = (coursebookId, unitId, exerciseId, preventDispatch) => ({
  type: REQUEST_COURSEBOOK_UNIT_EXERCISE,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise/${exerciseId}`
    },
    options: {
      preventDispatch: {
        onSuccess: () => !!preventDispatch
      }
    }
  }
});

const requestMainExercise: (
  coursebookId: string,
  unitId: number,
  exerciseId: string,
  expand?: boolean
) => AxiosRequestAction = (coursebookId, unitId, exerciseId, expand = true) => ({
  type: REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise/${exerciseId}`,
      params: {
        expand: expand ? 'unit.coursebook.languageLevelIds' : undefined
      }
    }
  }
});

const requestUnit: (
  coursebookId: string,
  unitId: number,
  expand?: boolean
) => AxiosRequestAction = (coursebookId, unitId, expand = true) => ({
  type: REQUEST_COURSEBOOK_UNIT,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}`,
      params: {
        expand: expand ? 'coursebook.languageLevelIds' : undefined
      }
    }
  }
});

const requestCategories: () => AxiosRequestAction = () => ({
  type: REQUEST_CATEGORIES,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/exercise-category`
    }
  }
});

export const requestLevels: () => AxiosRequestAction = () => ({
  type: REQUEST_LEVELS,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/dashboard/language-level`
    }
  }
});

const requestGrammar: (coursebookId: string, withSlateTitle?: boolean) => AxiosRequestAction = (
  coursebookId,
  withSlateTitle
) => ({
  type: REQUEST_GRAMMAR,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/coursebook/${coursebookId}/grammar`,
      params: {withSlateTitle}
    }
  }
});

export const selectGrammar = (grammar: Array<number>): GrammarSelectAction => ({
  type: SELECT_GRAMMAR,
  grammar
});

const createCategory: (title: string, parentId?: number | null) => AxiosRequestAction = (
  title,
  parentId = null
) => ({
  type: CREATE_CATEGORY,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: '/v2/exercise-category',
      data: {title, parentId}
    }
  }
});

const deleteCategory = (id: number): AxiosRequestAction => ({
  type: DELETE_CATEGORY,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `/v2/exercise-category/${id}`
    }
  }
});

function loadCategoriesDecorator<T>(
  requestFunc: (...args: Array<T[keyof T]>) => AxiosRequestAction
) {
  return (
      catcher: (reject?: AxiosRequestError) => void | null,
      responseSideEffect: ((response: AxiosResponseAction<ExerciseCategory>) => void) | null,
      ...args: Array<T[keyof T]>
    ) =>
    async (dispatch: Dispatch<AxiosRequestAction, AppState>) => {
      let response: AxiosResponseAction<ExerciseCategory> | null = null;
      try {
        response = await dispatch(requestFunc(...args));
        await dispatch(requestCategories());
      } catch (reject) {
        if (catcher) {
          catcher(reject);
        }
      }
      if (responseSideEffect && response) {
        responseSideEffect(response);
      }
    };
}

export const createCategoryAndLoad = loadCategoriesDecorator<{
  title: string;
  parentId?: number | null;
}>(createCategory);

export const deleteCategoryAndLoad = loadCategoriesDecorator<{id: number}>(deleteCategory);

const renameCategory: (title: string, id: number) => AxiosRequestAction = (title, id) => ({
  type: RENAME_CATEGORY,
  payload: {
    client: 'v2',
    request: {
      data: {title},
      method: 'PUT',
      url: `/v2/exercise-category/${id}`
    }
  }
});

export const renameCategoryAndLoad = loadCategoriesDecorator<{
  title: string;
  id: number;
}>(renameCategory);

export interface RequestLevelsResponseAction extends AxiosResponseAction<LanguageLevel[]> {}

export interface CoursebookUnitExerciseResponse extends XEditorUnitExercise {
  exercise?: ExerciseJSON;
  grammar?: GrammarExerciseOptions;
}

export interface SetUnitExerciseAction extends Action {
  payload: {unitExercise: XEditorUnitExercise};
}

export interface CoursebookUnitResponse extends CoursebookUnitShort {}

export interface CopyWidgetAction extends Action {
  widget: WidgetJSON;
}

export interface CopyUnitAction extends Action {
  unitId: number;
}

export interface CopyExerciseAction extends Action {
  exerciseId: string;
}

export const copyWidget = (widget: WidgetJSON): CopyWidgetAction => ({
  type: COPY_WIDGET,
  widget
});

export const copyUnit = (unitId: number): CopyUnitAction => ({
  type: COPY_UNIT,
  unitId
});

export const copyExercise = (exerciseId: string): CopyExerciseAction => ({
  type: COPY_EXERCISE,
  exerciseId
});

export const clearWidgetClipboard = (): Action => ({
  type: CLEAR_WIDGET_CLIPBOARD
});

export const clearUnitsClipboard = (): Action => ({
  type: CLEAR_UNITS_CLIPBOARD
});

export const clearExercisesClipboard = (): Action => ({
  type: CLEAR_EXERCISES_CLIPBOARD
});

interface ExerciseResponseAction extends Action {
  payload: {
    data: CoursebookUnitExerciseResponse;
  };
}

export const requestExerciseSuccess = (
  data: CoursebookUnitExerciseResponse
): ExerciseResponseAction => ({
  type: REQUEST_COURSEBOOK_UNIT_EXERCISE_SUCCESS,
  payload: {data}
});

export const resetLoading = (): Action => ({
  type: RESET_LOADING
});

export const setUnitExercise = (unitExercise: XEditorUnitExercise): SetUnitExerciseAction => ({
  type: SET_UNIT_EXERCISE,
  payload: {unitExercise}
});

export const resetWidgetErrors = (xwidgetId: string) => ({type: RESET_WIDGET_ERRORS, xwidgetId});

export const resetMediaSourcesErrors = (id?: string) => ({type: RESET_MEDIA_SOURCES_ERRORS, id});
