import {type Action} from 'redux';

import {type Coursebook, type CourseDetailed, type LanguageLevel} from 'store/interface';
import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';

import {type ServerCoursebook} from '../../../../components/CoursebookLibrary/interface';
import {
  CLOSE_COURSEBOOK_DATA_MODAL,
  COPY_COURSEBOOK,
  CREATE_COURSEBOOK,
  EDIT_COURSEBOOK,
  OPEN_COURSEBOOK_DATA_MODAL,
  REQUEST_AVAILABLE_COURSES,
  SET_AVAILABLE_COURSES,
  SET_AVAILABLE_LEVELS
} from './actionTypes';

interface OpenCoursebookData {
  editedCoursebook?: Coursebook;
  copiedCoursebook?: Coursebook;
}

export interface OpenCoursebookDataModalAction extends OpenCoursebookData, Action {}

export const openCoursebookDataModal = (
  data?: OpenCoursebookData
): OpenCoursebookDataModalAction => ({
  type: OPEN_COURSEBOOK_DATA_MODAL,
  ...data
});

export const closeCoursebookDataModal = () => ({
  type: CLOSE_COURSEBOOK_DATA_MODAL
});

export type RequestCoursesResponseAction = AxiosResponseAction<CourseDetailed[]>;

export const requestAvailableCourses = (): AxiosRequestAction => ({
  type: REQUEST_AVAILABLE_COURSES,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/dashboard/course`
    }
  }
});

export interface SetAvailableCoursesAction extends Action {
  availableCourses: CourseDetailed[];
}

export const setAvailableCourses = (
  availableCourses: CourseDetailed[]
): SetAvailableCoursesAction => ({
  type: SET_AVAILABLE_COURSES,
  availableCourses
});

export interface SetAvailableLevelsAction extends Action {
  availableLevels: LanguageLevel[];
}

export const setAvailableLevels = (availableLevels: LanguageLevel[]): SetAvailableLevelsAction => ({
  type: SET_AVAILABLE_LEVELS,
  availableLevels
});

export interface CoursebookOptions {
  title: string;
  levels: number[];
  courses: number[];
  selfCheck: boolean;
}

export const createCoursebook = ({
  title,
  courses,
  levels,
  selfCheck
}: CoursebookOptions): AxiosRequestAction => {
  return {
    type: CREATE_COURSEBOOK,
    payload: {
      client: 'v2',
      request: {
        method: 'POST',
        url: `/v2/coursebook`,
        data: {
          title,
          courses: courses.map(courseId => ({id: courseId})),
          levels: levels.map(levelId => ({id: levelId})),
          selfCheckEnabled: selfCheck
        }
      }
    }
  };
};

export type CreateCoursebookResponseAction = AxiosResponseAction<ServerCoursebook>;

export interface CoursebookOptionsWithId extends CoursebookOptions {
  id: string;
}

export const editCoursebook = ({
  id,
  title,
  courses,
  levels,
  selfCheck
}: CoursebookOptionsWithId): AxiosRequestAction => ({
  type: EDIT_COURSEBOOK,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `/v2/coursebook/${id}`,
      data: {
        title,
        courses: courses.map(courseId => ({id: courseId})),
        levels: levels.map(levelId => ({id: levelId})),
        selfCheckEnabled: selfCheck
      }
    }
  }
});

export const copyCoursebook = ({
  id,
  title,
  courses,
  levels,
  selfCheck
}: CoursebookOptionsWithId): AxiosRequestAction => ({
  type: COPY_COURSEBOOK,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: `/v2/coursebook/${id}`,
      data: {
        title,
        courses: courses.map(courseId => ({id: courseId})),
        levels: levels.map(levelId => ({id: levelId})),
        selfCheckEnabled: selfCheck
      }
    }
  }
});
