import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {injectIntl} from 'react-intl';

import {requestAvailableCourses} from 'routes/Library/Common/CoursebookDataModal/action';
import {requestLevels} from 'store/exercise/editor/actions/xeditor';
import injectReducer from 'store/injectReducer';
import {type AppState} from 'store/interface';

import coursebookLibraryReducer from '../reducers/coursebookLibraryReducer';
import {
  type AvailableAuthors,
  clearLibrary,
  requestAvailableAuthors,
  setAvailableAuthors,
  setLibraryError
} from '../actions/action';
import {
  type CoursebookLibraryOwnProps,
  type CoursebookLibraryStateProps,
  type CoursebookLibraryDispatchProps
} from '../components/interface';
import CoursebookLibrary from '../components/CoursebookLibrary';

const mapStateToProps = (
  state: AppState,
  {availableCourses, availableLevels}: CoursebookLibraryOwnProps
): CoursebookLibraryStateProps => {
  const {availableOriginalAuthors, availableAuthors} = state.coursebookLibrary!;
  const hasUnpublishedBookAccess = state.settings?.teacher?.vircUnpublishedBookAccess;
  return {
    isUpdatingLibrary: state.coursebookLibrary!.updatingLibrary,
    libraryHasError: state.coursebookLibrary!.hasError,
    availableLevels,
    availableCourses,
    availableAuthors,
    availableOriginalAuthors,
    hasUnpublishedBookAccess
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>
): CoursebookLibraryDispatchProps => ({
  clearLibrary: () => dispatch(clearLibrary()),
  setLibraryError: () => dispatch(setLibraryError()),

  setAvailableAuthors: (data: AvailableAuthors) => dispatch(setAvailableAuthors(data)),
  requestAvailableCourses: () => dispatch(requestAvailableCourses()),
  requestAvailableLevels: () => dispatch(requestLevels()),
  requestAvailableAuthors: (isOriginal: boolean) => dispatch(requestAvailableAuthors(isOriginal))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(CoursebookLibrary);

export default injectReducer({
  coursebookLibrary: coursebookLibraryReducer
})(injectIntl(Connected));
