import React from 'react';
import Collapse from 'react-bootstrap/lib/Collapse';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import {type CourseDetailed, type LanguageLevel} from 'store/interface';
import {CoursebookAuthorRole} from 'common/enums';

import {coursebookLibraryMessages} from '../../messages';
import CoursebookRoleDropdown from './CoursebookRoleDropdown';
import StatusDropdown from './StatusDropdown';
import ReactSelect from '../../../ReactSelect/ReactSelect';
import {filterAuthors} from './filterAuthors';
import {type ExtendedSearchPanelProps} from './interface';
import {type NumberOptionType, type OptionsType} from '../../../ReactSelect/interface';

import './ExtendedSearchPanel.scss';

interface State {
  authorSearchInput: string;
  originalAuthorSearchInput: string;
}

function isNonEmptyOption(
  option?: NumberOptionType | OptionsType<NumberOptionType>
): option is NumberOptionType {
  if (!option) {
    return false;
  }
  return (option as NumberOptionType).value !== undefined;
}

class ExtendedSearchPanel extends React.Component<
  ExtendedSearchPanelProps & WrappedComponentProps,
  State
> {
  public state: State = {
    authorSearchInput: '',
    originalAuthorSearchInput: ''
  };

  public render() {
    const {
      role,
      isOpen,
      intl,
      intl: {formatMessage},
      changeCoursebookPublishedFilter,
      filter: {published},
      hasUnpublishedBookAccess
    } = this.props;

    const rowClassNames = classNames('row', {hide: role === 'teacher'});
    const extraFilterClassNames = classNames('row', {
      hide: !(hasUnpublishedBookAccess || role === 'methodist')
    });

    return (
      <Collapse in={isOpen}>
        <div className="extended-search-panel">
          <Form className="content">
            <div className={rowClassNames}>
              {this.renderTypeControl()}

              {changeCoursebookPublishedFilter && this.renderAuthorControl('col-sm-3')}

              {changeCoursebookPublishedFilter && this.renderOriginalAuthorControl('col-sm-3')}
            </div>

            <div className={extraFilterClassNames}>
              {changeCoursebookPublishedFilter ? (
                <React.Fragment>
                  <ControlLabel className="col-sm-1">
                    {formatMessage(coursebookLibraryMessages.Status) + ': '}
                  </ControlLabel>
                  <FormGroup
                    controlId="type-control"
                    className="col-sm-2 inline coursebook-status-dropdown"
                  >
                    <StatusDropdown
                      published={published}
                      changeCoursebookPublishedFilter={changeCoursebookPublishedFilter}
                      intl={intl}
                    />
                  </FormGroup>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.renderAuthorControl('col-sm-4')}
                  <div className="col-sm-1" />
                  {this.renderOriginalAuthorControl('col-sm-4')}
                </React.Fragment>
              )}
            </div>

            <div className="row">
              <ControlLabel className="col-sm-1">
                {formatMessage(coursebookLibraryMessages.Course) + ': '}
              </ControlLabel>
              <FormGroup className="col-sm-11 inline coursebook-course-input">
                {this.renderCourseInput()}
              </FormGroup>
            </div>

            <div className="row">
              <ControlLabel className="col-sm-1">
                {formatMessage(coursebookLibraryMessages.Level) + ': '}
              </ControlLabel>
              <FormGroup className="col-sm-11 inline coursebook-level-input">
                {this.renderLevelInput()}
              </FormGroup>
            </div>
          </Form>
        </div>
      </Collapse>
    );
  }

  private renderTypeControl = () => {
    const {
      intl: {formatMessage},
      filter: {role},
      changeCoursebookRoleFilter,
      intl
    } = this.props;
    return (
      <React.Fragment>
        <ControlLabel className="col-sm-1">
          {formatMessage(coursebookLibraryMessages.CoursebookType) + ': '}
        </ControlLabel>
        <FormGroup controlId="type-control" className="col-sm-2 inline coursebook-type-dropdown">
          <CoursebookRoleDropdown
            role={role}
            changeCoursebookRoleFilter={changeCoursebookRoleFilter}
            intl={intl}
          />
        </FormGroup>
      </React.Fragment>
    );
  };

  private renderAuthorControl = (colClassName: string) => {
    const {
      availableAuthorsOptions,
      intl: {formatMessage},
      intl,
      filter
    } = this.props;
    return (
      <React.Fragment>
        <ControlLabel className="col-sm-1">
          {intl.formatMessage(coursebookLibraryMessages.Author) + ': '}
        </ControlLabel>
        <FormGroup
          controlId="author-control"
          className={`${colClassName} inline coursebook-author-input`}
        >
          <ReactSelect
            placeholder={formatMessage(coursebookLibraryMessages.AuthorPlaceholder)}
            options={this.authorsCustomFilter(
              availableAuthorsOptions,
              this.state.authorSearchInput
            )}
            isValueSelected={this.isAuthorValueSelected}
            onChange={this.onAuthorChanged}
            isMulti={false}
            filterOption={null}
            inputValue={this.state.authorSearchInput}
            onInputChange={this.onAuthorSearchInputChange}
            isDisabled={filter.role === CoursebookAuthorRole.METHODIST}
          />
        </FormGroup>
      </React.Fragment>
    );
  };

  // this wrapper cuts second argument that ReactSelect passes to it's onChange function, because changeAuthorFilter
  // of CoursebookLibrary component expects change route function as it's second argument
  private onAuthorChanged = (option: OptionsType<NumberOptionType>) =>
    this.props.changeAuthorFilter(isNonEmptyOption(option) ? option.value : null);

  private onAuthorSearchInputChange = (value: string) => this.setState({authorSearchInput: value});

  private isAuthorValueSelected = (option: NumberOptionType) =>
    option.value === this.props.filter.authorId;

  private authorsCustomFilter = (
    authors: OptionsType<NumberOptionType>,
    searchInputValue: string
  ) => filterAuthors(authors, searchInputValue);

  private renderOriginalAuthorControl = (colClassName: string) => {
    const {
      availableOriginalAuthorsOptions,
      intl: {formatMessage},
      intl
    } = this.props;
    return (
      <React.Fragment>
        <ControlLabel className="col-sm-2">
          {intl.formatMessage(coursebookLibraryMessages.OriginalAuthor) + ': '}
        </ControlLabel>
        <FormGroup
          controlId="original-author-control"
          className={`${colClassName} inline coursebook-author-input`}
        >
          <ReactSelect
            placeholder={formatMessage(coursebookLibraryMessages.AuthorPlaceholder)}
            options={this.authorsCustomFilter(
              availableOriginalAuthorsOptions,
              this.state.originalAuthorSearchInput
            )}
            isValueSelected={this.isOriginalAuthorValueSelected}
            onChange={this.onOriginalAuthorChanged}
            isMulti={false}
            filterOption={null}
            inputValue={this.state.originalAuthorSearchInput}
            onInputChange={this.onOriginalAuthorSearchInputChange}
          />
        </FormGroup>
      </React.Fragment>
    );
  };

  // this wrapper cuts second argument that ReactSelect passes to it's onChange function, because changeOriginalAuthorFilter
  // of CoursebookLibrary component expects change route function as it's second argument
  private onOriginalAuthorChanged = (option: OptionsType<NumberOptionType>) =>
    this.props.changeOriginalAuthorFilter(isNonEmptyOption(option) ? option.value : null);

  private onOriginalAuthorSearchInputChange = (value: string) =>
    this.setState({originalAuthorSearchInput: value});

  private isOriginalAuthorValueSelected = (option: NumberOptionType) =>
    option.value === this.props.filter.originalAuthorId;

  private renderCourseInput = () => {
    const {
      availableCourses,
      intl: {formatMessage}
    } = this.props;
    if (!availableCourses) {
      return null;
    }
    return (
      <ReactSelect
        placeholder={formatMessage(coursebookLibraryMessages.CoursePlaceholder)}
        onChange={this.onChangeCourse}
        options={availableCourses.map(this.mapCourseToOption)}
        isValueSelected={this.isCourseValueSelected}
      />
    );
  };

  private onChangeCourse = (selectedOptions: OptionsType<NumberOptionType> | null) =>
    this.props.changeCoursesFilter((selectedOptions || []).map(option => option.value));

  private isCourseValueSelected = (option: NumberOptionType) =>
    !!this.props.filter.courses &&
    !!this.props.filter.courses.find(courseId => courseId === option.value);

  private mapCourseToOption = (course: CourseDetailed) => ({
    value: course.id,
    label: course.name
  });

  private renderLevelInput = () => {
    const {
      availableLevels,
      intl: {formatMessage}
    } = this.props;
    if (!availableLevels) {
      return null;
    }
    return (
      <ReactSelect
        placeholder={formatMessage(coursebookLibraryMessages.LevelPlaceholder)}
        onChange={this.onChangeLevel}
        options={availableLevels.map(this.mapLevelToOption)}
        isValueSelected={this.isLevelValueSelected}
      />
    );
  };

  private onChangeLevel = (selectedOptions: OptionsType<NumberOptionType> | null) =>
    this.props.changeLevelsFilter((selectedOptions || []).map(option => option.value));

  private isLevelValueSelected = (option: NumberOptionType) =>
    !!this.props.filter.levels &&
    !!this.props.filter.levels.find(levelId => levelId === option.value);

  private mapLevelToOption = (level: LanguageLevel) => ({
    value: level.id,
    label: level.title
  });
}

export default ExtendedSearchPanel;
