import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {type AppState, type CoursebookFilter, type CoursebookUnit} from 'store/interface';
import {CoursebookAuthorRole} from 'common/enums';

import {
  ADD_TO_COURSEBOOK_LIST,
  CLEAR_COURSEBOOKS_LIST,
  CLEAR_LIBRARY,
  CLOSE_EXTENDED_SEARCH_PANEL,
  COPY_COURSEBOOK_UNITS,
  DELETE_COURSEBOOK,
  LOAD_COURSEBOOK_DATA,
  OPEN_EXTENDED_SEARCH_PANEL,
  REQUEST_AVAILABLE_AUTHORS,
  REQUEST_COURSEBOOKS_LIST,
  SET_AVAILABLE_AUTHORS,
  SET_COURSEBOOK_LIST,
  SET_LIBRARY_ERROR,
  SET_LIBRARY_UPDATING,
  SET_TOTAL_COURSEBOOKS_COUNT
} from './actionTypes';
import {type ServerCoursebook, type UserV2} from '../interface';
import {methodologyOptionId} from '../components/ExtendedSearchPanel/filterAuthors';
import {setCoursebookUnits} from '../UnitsList/action';
import {setCoursebookInfo} from '../../../routes/Library/CoursebookPage/state/action';

export const openExtendedSearchPanel = () => ({
  type: OPEN_EXTENDED_SEARCH_PANEL
});

export const closeExtendedSearchPanel = () => ({
  type: CLOSE_EXTENDED_SEARCH_PANEL
});

export interface AvailableAuthors {
  availableAuthors: UserV2[];
  availableOriginalAuthors: UserV2[];
}

export type RequestAuthorsResponseAction = AxiosResponseAction<UserV2[]>;
export type LoadCoursebookDataResponseAction = AxiosResponseAction<LoadCoursebookDataResponse>;

export type LoadCoursebookDataResponse = ServerCoursebook & {units: CoursebookUnit[]};

type CloneUnitsResult = Array<{
  sourceId: number;
  cloneId: number;
}>;

export const loadCoursebookData = (id: string, expandUnitOverview?: true): AxiosRequestAction => {
  return {
    type: LOAD_COURSEBOOK_DATA,
    payload: {
      client: 'v2',
      request: {
        method: 'get',
        url: `/v2/coursebook/${id}`,
        params: expandUnitOverview && {
          expand: 'unit.overview,hasGrammar'
        }
      }
    }
  };
};

export const deleteCoursebook = (id: string): AxiosRequestAction => ({
  type: DELETE_COURSEBOOK,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `/v2/coursebook/${id}`
    }
  }
});

export const requestAvailableAuthors = (isOriginal: boolean): AxiosRequestAction => ({
  type: REQUEST_AVAILABLE_AUTHORS,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: 'v2/coursebook/author',
      params: {
        role: 'teacher',
        original: isOriginal
      }
    }
  }
});

export interface SetAvailableAuthorsAction extends Action, AvailableAuthors {}

export const setAvailableAuthors = (
  availableAuthors: AvailableAuthors
): SetAvailableAuthorsAction => ({
  type: SET_AVAILABLE_AUTHORS,
  ...availableAuthors
});

interface CoursebookServerFilter extends CoursebookFilter {
  originalAuthorRole: CoursebookAuthorRole;
}

export const requestCoursebooks = (
  filter: CoursebookFilter,
  pageNumber: number,
  pageSize: number
): AxiosRequestAction => {
  const selectMethodologyAuthors =
    filter.authorId === methodologyOptionId || filter.role === CoursebookAuthorRole.METHODIST;
  const selectMethodologyOriginalAuthors = filter.originalAuthorId === methodologyOptionId;
  return {
    type: REQUEST_COURSEBOOKS_LIST,
    payload: {
      client: 'v2',
      request: {
        method: 'get',
        url: '/v2/coursebook',
        params: {
          ...filter,
          pageSize,
          pageNumber,
          authorId: selectMethodologyAuthors ? undefined : filter.authorId,
          role: selectMethodologyAuthors ? CoursebookAuthorRole.METHODIST : filter.role,
          originalAuthorId: selectMethodologyOriginalAuthors ? undefined : filter.originalAuthorId,
          originalAuthorRole: selectMethodologyOriginalAuthors
            ? CoursebookAuthorRole.METHODIST
            : undefined,
          expand: 'hasGrammar'
        } as CoursebookServerFilter
      }
    }
  };
};

export type RequestCoursebookResponseAction = AxiosResponseAction<ServerCoursebook[]>;

export interface SetLibraryUpdatingAction extends Action {
  isUpdating: boolean;
}

export const setLibraryUpdating = (isUpdating: boolean): SetLibraryUpdatingAction => ({
  type: SET_LIBRARY_UPDATING,
  isUpdating
});

export const clearLibrary = (): Action => ({
  type: CLEAR_LIBRARY
});

export interface SetCoursebookListAction extends Action {
  list: ServerCoursebook[];
}

export const setCoursebookList = (list: ServerCoursebook[]): SetCoursebookListAction => ({
  type: SET_COURSEBOOK_LIST,
  list
});

export interface SetTotalCountAction extends Action {
  totalCount: number;
}

export const setTotalCoursebooksCount = (totalCount: number): SetTotalCountAction => ({
  type: SET_TOTAL_COURSEBOOKS_COUNT,
  totalCount
});

export const addToCoursebookList = (list: ServerCoursebook[]): SetCoursebookListAction => ({
  type: ADD_TO_COURSEBOOK_LIST,
  list
});

export const setLibraryError = () => ({
  type: SET_LIBRARY_ERROR
});

export const clearCoursebooksList = (): Action => ({
  type: CLEAR_COURSEBOOKS_LIST
});

const copyCoursebookUnits = (coursebookId: string, unitIds: number[]): AxiosRequestAction => ({
  type: COPY_COURSEBOOK_UNITS,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `/v2/coursebook/${coursebookId}/unit`,
      data: {
        unitIds
      }
    }
  }
});

export const copyUnits =
  (coursebookId: string, units: number[]) => async (dispatch: Dispatch<Action, AppState>) => {
    const {
      payload: {data: copied}
    } = await dispatch<AxiosResponseAction<CloneUnitsResult>>(
      copyCoursebookUnits(coursebookId, units)
    );

    const copiedUnits = copied.map(unit => unit.cloneId);

    const {
      payload: {data: courseData}
    }: LoadCoursebookDataResponseAction = await dispatch(loadCoursebookData(coursebookId, true));

    await dispatch(setCoursebookUnits(courseData.units));
    await dispatch(setCoursebookInfo(courseData));

    return copiedUnits;
  };
