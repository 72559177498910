import React from 'react';
import {type WrappedComponentProps} from 'react-intl';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import {CoursebookAuthorRole} from 'common/enums';

import {coursebookLibraryMessages} from '../../messages';
import Icon from '../../../Icon';
import {type SelectCallback} from '../../../interface';

interface Props extends WrappedComponentProps {
  role: CoursebookAuthorRole | null;
  changeCoursebookRoleFilter: (role: CoursebookAuthorRole | null) => void;
}

export default class CoursebookRoleDropdown extends React.Component<Props> {
  public render() {
    const {
      intl: {formatMessage},
      role
    } = this.props;
    const anyMsg = formatMessage(coursebookLibraryMessages.DropdownValueAny);
    const commonMsg = formatMessage(coursebookLibraryMessages.CoursebookTypeCommon);
    const proprietaryMsg = formatMessage(coursebookLibraryMessages.CoursebookTypeProprietary);
    const dropdownTitleText =
      (role === CoursebookAuthorRole.METHODIST && commonMsg) ||
      (role === CoursebookAuthorRole.TEACHER && proprietaryMsg) ||
      anyMsg;
    return (
      <DropdownButton
        id="coursebook-extended-search-role"
        title={
          <React.Fragment>
            <span key="1">{dropdownTitleText}</span>
            <Icon key="2" name="angle-down" className="custom-caret" />
          </React.Fragment>
        }
        onSelect={this.selectCallback as SelectCallback}
        className="dropdown-toggle"
        noCaret={true}
      >
        <MenuItem active={!role} eventKey={null}>
          {anyMsg}
        </MenuItem>
        <MenuItem
          active={role === CoursebookAuthorRole.METHODIST}
          eventKey={CoursebookAuthorRole.METHODIST}
        >
          {commonMsg}
        </MenuItem>
        <MenuItem
          active={role === CoursebookAuthorRole.TEACHER}
          eventKey={CoursebookAuthorRole.TEACHER}
        >
          {proprietaryMsg}
        </MenuItem>
      </DropdownButton>
    );
  }

  private selectCallback = (eventKey: CoursebookAuthorRole | null) => {
    // pass this function as callback so that event doesn't get passed as second call param
    this.props.changeCoursebookRoleFilter(eventKey);
  };
}
