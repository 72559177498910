import {defineMessages} from 'react-intl';

export const coursebookLibraryMessages = defineMessages({
  Author: {
    id: 'CoursebookLibrary.Author'
  },
  OriginalAuthor: {
    id: 'CoursebookLibrary.OriginalAuthor'
  },
  Units: {
    id: 'CoursebookLibrary.Units'
  },
  Pages: {
    id: 'CoursebookLibrary.Pages'
  },
  Exercises: {
    id: 'CoursebookLibrary.Exercises'
  },
  EnglexDepartmentOfMethodology: {
    id: 'CoursebookLibrary.EnglexDepartmentOfMethodology'
  },
  Course: {
    id: 'CoursebookLibrary.Course'
  },
  CoursePlaceholder: {
    id: 'CoursebookLibrary.CoursePlaceholder'
  },
  Level: {
    id: 'CoursebookLibrary.Level'
  },
  LevelPlaceholder: {
    id: 'CoursebookLibrary.LevelPlaceholder'
  },
  CoursebookType: {
    id: 'CoursebookLibrary.CoursebookType'
  },
  DropdownValueAny: {
    id: 'CoursebookLibrary.DropdownValueAny'
  },
  CoursebookTypeCommon: {
    id: 'CoursebookLibrary.CoursebookTypeCommon'
  },
  CoursebookTypeProprietary: {
    id: 'CoursebookLibrary.CoursebookTypeProprietary'
  },
  AuthorPlaceholder: {
    id: 'CoursebookLibrary.AuthorPlaceholder'
  },
  Status: {
    id: 'CoursebookLibrary.CoursebookStatus'
  },
  StatusHidden: {
    id: 'CoursebookLibrary.CoursebookStatus.Hidden'
  },
  StatusPublished: {
    id: 'CoursebookLibrary.CoursebookStatus.Published'
  },
  CoursebookDeletedSuccessToast: {
    id: 'CoursebookLibrary.CoursebookDeleteSuccessToast'
  },
  CoursebookCoverAttachError: {
    id: 'CoursebookLibrary.Coursebook.CoverAttachError'
  },
  CoursebookCoverDeleteError: {
    id: 'CoursebookLibrary.Coursebook.CoverDeleteError'
  },
  CoursebookCoverDeleteConfirm: {
    id: 'CoursebookLibrary.Coursebook.CoverDeleteConfirm'
  },
  CoursebookInfoRefreshError: {
    id: 'CoursebookLibrary.Coursebook.InfoRefreshError'
  },
  PasteUnitsError: {
    id: 'Coursebook.CopyToClipboard.PasteUnitsError'
  },
  ExceedUnits: {
    id: 'Coursebook.CopyToClipboard.ExceedUnits'
  }
});
