import React from 'react';

import Spinner from 'components/Spinner';

interface Props {
  showSpinner?: boolean;
  isRevision?: boolean;
  ordinal: number | null;
}

export default class UnitItemTitleIcon extends React.Component<Props> {
  public render() {
    const {showSpinner, isRevision, ordinal} = this.props;
    return (
      <span className={`label${isRevision ? ' revision' : ''}`}>
        {showSpinner ? null : isRevision ? 'T' : ordinal}
        {showSpinner && <Spinner size={15} />}
      </span>
    );
  }
}
