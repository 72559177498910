import React from 'react';
import {defineMessages, FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';

import {type CoursebookLibraryHeaderProps} from './interface';
import SearchBar from '../../SearchBar';
import Icon from '../../Icon';

import './CoursebookLibraryHeader.scss';

const messages = defineMessages({
  CoursebookSearchbarPlaceholder: {
    id: 'CoursebookLibrary.SearchbarPlaceholder'
  }
});

class CoursebookLibraryHeader extends React.Component<
  CoursebookLibraryHeaderProps & WrappedComponentProps
> {
  constructor(props: CoursebookLibraryHeaderProps & WrappedComponentProps) {
    super(props);

    if (props.role === 'teacher') {
      this.props.openExtendedSearchPanel();
    }
  }

  public render() {
    const {
      intl,
      extendedSearchPanelOpen,
      canResetFilter,
      titleFilter,
      changeCoursebookTitleFilter,
      createCoursebook,
      openExtendedSearchPanel,
      closeExtendedSearchPanel,
      title
    } = this.props;
    const extendedSearchPanelBtnClass = classNames('extended-search-panel-btn', {
      active: extendedSearchPanelOpen
    });
    return (
      <div className="coursebook-library-header">
        {title && <b className="coursebook-library-title">{title}</b>}
        <SearchBar
          filter={titleFilter}
          changeFilter={changeCoursebookTitleFilter}
          isSmall={true}
          isMobile={true}
          placeholder={intl.formatMessage(messages.CoursebookSearchbarPlaceholder)}
          clearOnUnmount={false}
        />
        <Tooltip trigger={['hover']} overlay={this.tooltipOverlay} placement="top">
          <button
            className={extendedSearchPanelBtnClass}
            onClick={extendedSearchPanelOpen ? closeExtendedSearchPanel : openExtendedSearchPanel}
          >
            <Icon name="sliders" />
          </button>
        </Tooltip>
        {canResetFilter ? this.renderResetFilterBtn() : null}
        {createCoursebook && (
          <Button
            bsStyle="success"
            bsSize="sm"
            className="create-coursebook-btn"
            onClick={createCoursebook}
          >
            <FormattedMessage id="CoursebookLibrary.CreateCoursebook" />
            <Icon name="plus-circle" className="mobile-icon" />
          </Button>
        )}
      </div>
    );
  }

  private renderResetFilterBtn = () => (
    <Button
      className="btn-transparent reset-filters-btn"
      bsSize="sm"
      onClick={this.props.resetFilters}
    >
      <FormattedMessage id="CoursebookLibrary.ResetFilters" />
      <Icon name="pc-drop-filter" className="mobile-icon drop-filters-icon" />
    </Button>
  );

  private tooltipOverlay = () =>
    this.props.extendedSearchPanelOpen ? (
      <FormattedMessage id="CoursebookLibrary.BasicSearch" />
    ) : (
      <FormattedMessage id="CoursebookLibrary.ExtendedSearch" />
    );
}

export default injectIntl(CoursebookLibraryHeader);
