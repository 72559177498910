import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import Loader from '../Loader';
import SlatePlayer from '../Slate/SlatePlayer/SlatePlayer';
import {type ContentsItem} from './interface';
import EmptyUnitPage from './EmptyUnitPage';
import Bold from '../Slate/plugins/renderers/Bold';
import Italic from '../Slate/plugins/renderers/Italic';
import DecimalOrderedList from '../Slate/plugins/renderers/List/Decimal';
import AlphaOrderedList from '../Slate/plugins/renderers/List/Alpha';
import UnorderedList from '../Slate/plugins/renderers/List/UnorderedList';
import ListItem from '../Slate/plugins/renderers/List/ListItem';
import Icon from '../Slate/plugins/renderers/Icon';
import './ContentsView.scss';

interface Props {
  contents?: ContentsItem[];
}

export default class ContentsView extends React.Component<Props> {
  public static plugins = [
    new Bold(),
    new Italic(),
    new DecimalOrderedList(),
    new AlphaOrderedList(),
    new UnorderedList(),
    new ListItem(),
    new Icon()
  ];
  public render() {
    return <div className="view-contents-column">{this.renderInnerContent()}</div>;
  }

  private renderInnerContent = () => {
    const {contents} = this.props;
    if (!contents) {
      return <Loader />;
    }
    if (contents.length === 0) {
      return <EmptyUnitPage />;
    }
    return (
      <Scrollbars autoHide={true}>
        <div className="loaded-contents">{contents.map(this.renderContentsItem)}</div>
      </Scrollbars>
    );
  };

  private renderContentsItem = ({title, description, id}: ContentsItem) => {
    return (
      <div className="contents-item" key={id}>
        <b className="title">{title}</b>
        <SlatePlayer value={description} plugins={ContentsView.plugins} />
      </div>
    );
  };
}
