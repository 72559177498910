import React, {type PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {type AppState, type CoursebookUnit, type Role} from 'store/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {type ExerciseJSON, type GrammarExerciseIds} from 'store/exercise/player/interface';
import {type XPreviewProperties} from 'store/exercise/player/preview/interface';
import {changePreviewRole} from 'store/exercise/player/preview/actions';

import {
  cleanUnitPreview,
  initUnitPreview,
  requestPreviewUnitPage,
  requestUnitForPreview
} from './actions';
import WampErrorMask from '../../WampErrorMask';
import {UnitPreview} from './UnitPreview';
import {type UnitPreviewProps} from './UnitPreviewTypes';

interface OwnProps {
  unitId: number;
  coursebookId: string;
  Component?: React.ComponentType<UnitPreviewProps>;
  courseInstanceIsActive?: boolean;
  pageNumber?: number;
  scrollToExerciseId?: string;
  changePage?: (page: number) => void;
}

interface StateProps {
  role?: Role;
  xpreview: XPreviewProperties | null;
  mainId: string | null;
  grammarPreview?: string;
}

interface Props extends StateProps, OwnProps {}

const UnitPreviewContainer: React.FC<Props> = ({
  role,
  coursebookId,
  unitId,
  mainId,
  xpreview,
  pageNumber: page,
  Component = UnitPreview,
  courseInstanceIsActive,
  grammarPreview,
  scrollToExerciseId,
  changePage
}: PropsWithChildren<Props>) => {
  const dispatch = useAxiosDispatch();
  const [pagesCount, setPagesCount] = useState<number | null>(null);
  const [localPageNumber, setLocalPageNumber] = useState<number>(1);
  const [unit, setUnit] = useState<CoursebookUnit | null>(null);

  const pageNumber = page || localPageNumber;
  const setPageNumber = changePage || setLocalPageNumber;

  const addPreviewExerciseNumber = role === 'methodist';

  const unitRequestAction = useMemo(
    () => requestUnitForPreview(coursebookId, unitId),
    [coursebookId, unitId]
  );

  const unitRequestSuccessHandler = useCallback(
    (response: CoursebookUnit) => {
      setUnit(response);

      const pagesCount = response.overview!.pagesCount;

      setPagesCount(pagesCount);

      if (pageNumber > pagesCount) {
        setPageNumber(1);
      }
    },
    [pageNumber, setPageNumber]
  );

  const {isError: unitRequestFailed, reload: reloadUnit} = useApiRequest(
    unitRequestAction,
    unitRequestSuccessHandler
  );

  const unitPageRequestAction = useMemo(
    () => requestPreviewUnitPage(coursebookId, unitId, pageNumber),
    [coursebookId, unitId, pageNumber]
  );

  const unitPageRequestSuccessHandler = useCallback(
    (unitExercises: [{exercise: ExerciseJSON; grammar: GrammarExerciseIds}]) => {
      const exercises = unitExercises.map(ue => ({...ue.exercise, grammar: ue.grammar}));
      dispatch(
        initUnitPreview(coursebookId, unitId, exercises, addPreviewExerciseNumber, pageNumber)
      );
    },
    [dispatch, coursebookId, unitId, addPreviewExerciseNumber, pageNumber]
  );

  const changeRole = useCallback(role => dispatch(changePreviewRole(role)), [dispatch]);

  const {
    isError: unitPageRequestFailed,
    reload: reloadUnitPage,
    isLoading: loadingUnitPage
  } = useApiRequest(unitPageRequestAction, unitPageRequestSuccessHandler);

  useEffect(
    () => () => {
      dispatch(cleanUnitPreview);
    },
    [dispatch]
  );

  if (unitRequestFailed) {
    return <WampErrorMask reload={reloadUnit} />;
  }
  if (unitPageRequestFailed) {
    return <WampErrorMask reload={reloadUnitPage} />;
  }
  return (
    <Component
      isLoading={loadingUnitPage || !pagesCount || !xpreview}
      xpreview={xpreview}
      pageNumber={pageNumber}
      pageCount={pagesCount}
      mainId={mainId}
      coursebookId={coursebookId}
      unitId={unitId}
      unit={unit}
      courseInstanceIsActive={courseInstanceIsActive}
      grammarPreview={grammarPreview}
      scrollToExerciseId={scrollToExerciseId}
      changeRole={changeRole}
      selectPage={setPageNumber}
    />
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  role: state.user?.role,
  mainId: state.unitPreview?.mainId || null,
  xpreview: state.unitPreview?.xpreview || null,
  grammarPreview: state.unitPreview?.grammarPreview
});

export default connect(mapStateToProps)(UnitPreviewContainer);
