import {type Action} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';
import {type CoursebookUnit} from 'store/interface';

import {
  CREATE_UNIT,
  DELETE_UNIT,
  REARRANGE_UNITS,
  RENAME_UNIT,
  SET_COURSEBOOK_UNITS,
  SET_EDITED_UNIT,
  SET_UNIT_TO_DELETE
} from './actionTypes';
import {type CoursebookUnitAction} from '../../../routes/Library/CoursebookPage/interface';

export const createUnit = (
  coursebookId: string,
  isRevision: boolean,
  title: string
): AxiosRequestAction => ({
  type: CREATE_UNIT,
  payload: {
    client: 'v2',
    request: {
      headers: {'Content-Type': 'application/json'},
      data: {isRevision, title},
      method: 'post',
      url: `/v2/coursebook/${coursebookId}/unit`
    }
  }
});

export const renameUnit = (
  coursebookId: string,
  unitId: number,
  title: string
): AxiosRequestAction => ({
  type: RENAME_UNIT,
  payload: {
    client: 'v2',
    request: {
      headers: {'Content-Type': 'application/json'},
      data: {title},
      method: 'put',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}`
    }
  }
});

export const deleteUnit = (coursebookId: string, unitId: number): AxiosRequestAction => ({
  type: DELETE_UNIT,
  payload: {
    client: 'v2',
    request: {
      headers: {'Content-Type': 'application/json'},
      method: 'delete',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}`
    }
  }
});

export const requestUnitsRearrangement = (
  coursebookId: string,
  sequence: number,
  unitId: number
): AxiosRequestAction => ({
  type: REARRANGE_UNITS,
  payload: {
    client: 'v2',
    request: {
      headers: {'Content-Type': 'application/json'},
      data: {id: unitId, sequence},
      params: {
        expand: 'overview'
      },
      method: 'put',
      url: `/v2/coursebook/${coursebookId}/unit`
    }
  }
});

export const setEditedUnitId = (unitId?: number): CoursebookUnitAction => ({
  type: SET_EDITED_UNIT,
  unitId
});

export const setUnitIdToDelete = (unitId?: number): CoursebookUnitAction => ({
  type: SET_UNIT_TO_DELETE,
  unitId
});

export interface SetCoursebookUnitsAction extends Action {
  units: CoursebookUnit[];
}

export const setCoursebookUnits = (units: CoursebookUnit[]): SetCoursebookUnitsAction => ({
  type: SET_COURSEBOOK_UNITS,
  units
});
