import React from 'react';
import classNames from 'classnames';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import {type ConnectDropTarget} from 'react-dnd';

import Icon from 'components/Icon';

import {dropdownDropupCheckCreator} from '../../../helpers/dropdownDropupCheck';
import {type LibraryListAlignment} from './constants';

import './LibraryPageListEl.scss';

interface Props {
  dropdownId?: string;
  connectDropTarget?: ConnectDropTarget;
  dragging?: boolean;
  dragItemId?: number;
  getContainer?: () => HTMLElement;
  isActive: boolean;
  processingRequest?: boolean;
  handleClick: () => void;
  title: string;
  dropdownButtons: JSX.Element[];
  alignment: LibraryListAlignment;
  getRef?: (el: HTMLDivElement) => void;
  isDragItem?: boolean;
  renderHandle: () => JSX.Element | null;
  elementId?: string;
  animated?: boolean;
  onAnimationEnd?: () => void;
  withAutoScroll?: boolean;
}

interface State {
  dropdownIsOpen: boolean;
  dropup?: boolean;
}

class LibraryPageListEl extends React.Component<Props, State> {
  public static defaultWidth = 325;
  private static dropdownBtnHeight = 28;
  private static dropdownPaddingTop = 8;
  private static dropdownPaddingBottom = 4;

  private component: HTMLDivElement | null;

  public state: State = {dropdownIsOpen: false};

  private dropdownDropupCheck = dropdownDropupCheckCreator(
    (dropup: boolean) => this.setState({dropup}),
    this.dropdownHeight,
    this.props.getContainer
  );

  private get classes() {
    const {alignment, isActive, dragging, processingRequest, dragItemId, isDragItem, animated} =
      this.props;
    return classNames('library-page-list-el', `align-${alignment.toLowerCase()}`, {
      animated: animated,
      active: isActive,
      dragging,
      'dropdown-open': this.state.dropdownIsOpen,
      'processing-request': processingRequest,
      // when react-dnd performs drag, :hover state does not recalculate
      // this is workaround
      'not-dragging': dragItemId && !dragging,
      'is-drag-item': isDragItem
    });
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps.withAutoScroll && this.props.withAutoScroll && this.component) {
      return this.component.scrollIntoView({block: 'center', behavior: 'smooth'});
    }
  }

  public render() {
    const {connectDropTarget} = this.props;
    return connectDropTarget ? connectDropTarget(this.renderView()) : this.renderView();
  }

  private renderView = () => {
    const {dropdownId, title, dropdownButtons, renderHandle, elementId, onAnimationEnd} =
      this.props;
    return (
      <div
        className={this.classes}
        onClick={this.handleClick}
        ref={this.componentRef}
        id={elementId}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="content">
          <div className="info">
            {this.props.children}
            <span className="title" title={title}>
              {title}
            </span>
          </div>
          <div className="actions">
            {dropdownButtons.length ? (
              <DropdownButton
                id={dropdownId || ''}
                noCaret={true}
                onToggle={this.onDropdownToggle}
                onClick={this.onDropdownClick}
                title={<Icon name="virc-actions" />}
                dropup={this.state.dropup}
              >
                {dropdownButtons}
              </DropdownButton>
            ) : null}
            {renderHandle()}
          </div>
        </div>
      </div>
    );
  };

  private componentRef = (el: HTMLDivElement) => {
    this.component = el;
    this.props.getRef?.(el);
  };

  private handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (this.state.dropdownIsOpen) {
      return;
    }
    this.props.handleClick();
  };

  private onDropdownClick = (e: React.MouseEvent<DropdownButton>) => {
    this.dropdownDropupCheck(e);
    e.stopPropagation();
  };

  private onDropdownToggle = (isOpen: boolean) => {
    this.setState({dropdownIsOpen: isOpen});
  };

  private get dropdownHeight() {
    return (
      this.props.dropdownButtons.length * LibraryPageListEl.dropdownBtnHeight +
      LibraryPageListEl.dropdownPaddingBottom +
      LibraryPageListEl.dropdownPaddingTop
    );
  }
}

export default LibraryPageListEl;
