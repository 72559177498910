import React from 'react';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {connect} from 'react-redux';

import {type AppState, type CoursebookSection, type CoursebookUnit} from 'store/interface';

import {
  requestCoursebookContentsBySection,
  requestCoursebookContentsByUnit,
  requestCoursebookSections
} from './action';
import {type ViewContentsModalTab} from './interface';
import {loadCoursebookData} from '../CoursebookLibrary/actions/action';
import ContentsViewer from './ContentsViewer';

interface OwnProps {
  selectedTab: ViewContentsModalTab;
  coursebookId: string;
}

interface DispatchProps {
  loadCoursebookUnits: (coursebookId: string) => Promise<Action>;
  loadCoursebookSections: (coursebookId: string) => Promise<Action>;
  requestContentsBySection: (coursebookId: string, sectionId: number) => Promise<Action>;
  requestContentsByUnit: (coursebookId: string, unitId: number) => Promise<Action>;
}

interface Props extends OwnProps, DispatchProps {}

interface State {
  units?: CoursebookUnit[];
  sections?: CoursebookSection[];
  selectedUnitId?: number;
  selectedSectionId?: number;
  requestFailed?: boolean;
}

class CoursebookContentsViewer extends React.Component<Props, State> {
  public render() {
    return (
      <ContentsViewer
        selectedTab={this.props.selectedTab}
        coursebookId={this.props.coursebookId}
        loadCoursebookUnits={this.props.loadCoursebookUnits}
        loadCoursebookSections={this.props.loadCoursebookSections}
        requestContentsBySection={this.props.requestContentsBySection}
        requestContentsByUnit={this.props.requestContentsByUnit}
      />
    );
  }
}

const mapDisaptchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  loadCoursebookUnits: (coursebookId: string) => dispatch(loadCoursebookData(coursebookId)),
  loadCoursebookSections: (coursebookId: string) =>
    dispatch(requestCoursebookSections(coursebookId)),
  requestContentsBySection: (coursebookId: string, sectionId: number) =>
    dispatch(requestCoursebookContentsBySection(coursebookId, sectionId)),
  requestContentsByUnit: (coursebookId: string, unitId: number) =>
    dispatch(requestCoursebookContentsByUnit(coursebookId, unitId))
});

export default connect(null, mapDisaptchToProps)(CoursebookContentsViewer);
