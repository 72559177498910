import React, {useCallback, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import Loader from 'components/Loader';
import ExtraExercisesList from 'routes/ClassRoom/pages/XPlayerPage/SupplementaryExercisesModal/ExtraExercisesList';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type ExerciseJSON, type GrammarExerciseIds} from 'store/exercise/player/interface';
import {type AppState} from 'store/interface';
import XPlayer from 'components/XPlayer';
import {type XPreviewProperties} from 'store/exercise/player/preview/interface';
import {changePreviewRole} from 'store/exercise/player/preview/actions';
import {BootstrapBreakpoint} from 'config/static';

import {initUnitPreviewAdditional, requestUnitPreviewAdditionalExercises} from './actions';
import {useApiRequest} from '../../../hooks/rest/useApiRequest';
import WampErrorMask from '../../WampErrorMask';
import RoleSwitcher from '../../XPreview/RoleSwitcher';
import {
  FloatSidebar,
  FloatSidebarPosition,
  FloatSidebarProvider
} from '../../FloatSidebar/FloatSidebar';
import {CollapsedSidebarButton} from '../../FloatSidebar/CollapsedSidebarButton';

import './ExtraExercisesPreview.scss';

interface OwnProps {
  coursebookId: string;
  unitId: number;
  mainId: string;
}

interface StateProps {
  xpreview: XPreviewProperties | null;
}

type Props = OwnProps & StateProps;

const ExtraExercisesPreview: React.FC<Props> = ({coursebookId, unitId, mainId, xpreview}) => {
  const [additional, setAdditional] = useState<ExerciseJSON[] | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);

  const dispatch = useAxiosDispatch();
  const selectExercise = useCallback(
    id => {
      setActiveId(id);
      const exercise = additional && additional.find(e => e.id === id);
      if (exercise) {
        dispatch(initUnitPreviewAdditional(exercise));
      }
    },
    [additional, dispatch]
  );

  const requestPreviewAction = useMemo(
    () => requestUnitPreviewAdditionalExercises(coursebookId, unitId, mainId),
    [coursebookId, unitId, mainId]
  );

  const requestPreviewSuccessHandler = useCallback(
    (unitExercises: [{exercise: ExerciseJSON; grammar: GrammarExerciseIds}]) => {
      const exercises = unitExercises.map(ue => ({...ue.exercise, grammar: ue.grammar}));
      const [first] = exercises;

      setAdditional(exercises);
      setActiveId(first.id);
      dispatch(initUnitPreviewAdditional(first));
    },
    [dispatch]
  );

  const {isLoading, isError, reload} = useApiRequest(
    requestPreviewAction,
    requestPreviewSuccessHandler
  );

  if (isError) {
    return <WampErrorMask reload={reload} />;
  }

  if (isLoading || !activeId || !xpreview) {
    return <Loader />;
  }

  return (
    <div className="extra-exercises-preview">
      <RoleSwitcher changeRole={role => dispatch(changePreviewRole(role))} role={xpreview.role} />
      <div className="extra-exercises-preview-container">
        <FloatSidebar
          provider={FloatSidebarProvider.State}
          breakpoint={BootstrapBreakpoint.LG}
          position={FloatSidebarPosition.Left}
          collapsedButton={(clickHandler, sidebarCollapsed) => (
            <CollapsedSidebarButton
              onClick={clickHandler}
              shouldBeRendered={sidebarCollapsed || false}
            />
          )}
          layoutCollapsed={false}
        >
          <ExtraExercisesList
            exercises={additional!}
            selectedId={activeId}
            selectExercise={selectExercise}
          />
        </FloatSidebar>
        {xpreview && (
          <XPlayer
            preview={true}
            exercises={xpreview.exercises}
            role={xpreview.role}
            isModal={true}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  return {
    xpreview: (state.unitPreview && state.unitPreview.extraPreview) || null
  };
};
export default connect(mapStateToProps)(ExtraExercisesPreview);
