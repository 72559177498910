import React from 'react';
import {type Action} from 'redux';
import {Value} from '@englex/slate';

import {type CoursebookSection, type CoursebookUnit} from 'store/interface';
import {BootstrapBreakpoint} from 'config/static';

import ContentsView from './ContentsView';
import ItemsListColumn from './ItemsListColumn';
import {type CancellablePromise, makeCancellable} from '../../helpers/cancellablePromise';
import {type GetContentsBySectionResponse} from './action';
import {type ContentsItem} from './interface';
import {LibraryListAlignment} from '../CoursebookLibrary/LibraryPageList/constants';
import LibraryPageListEl from '../CoursebookLibrary/LibraryPageList/LibraryPageListEl';
import UnitItemTitleIcon from '../CoursebookLibrary/UnitsList/views/UnitItemTitleIcon';
import {
  FloatSidebar,
  FloatSidebarPosition,
  FloatSidebarProvider
} from '../FloatSidebar/FloatSidebar';
import {CollapsedSidebarButton} from '../FloatSidebar/CollapsedSidebarButton';

interface Props {
  coursebookUnits: CoursebookUnit[];
  coursebookSections: CoursebookSection[];
  selectedUnitId?: number;
  sidebarPulledOut: boolean;
  selectUnit: (unitId: number) => void;
  requestContents: (unitId: number) => Promise<Action>;
  handleRequestError: () => void;
  toggleSidebar: () => void;
}

interface State {
  contents?: ContentsItem[];
}

export default class ContentsByUnits extends React.Component<Props, State> {
  public state: State = {};
  private request: CancellablePromise;

  public componentWillUnmount(): void {
    if (this.request) {
      this.request.cancel();
    }
  }

  public componentDidMount(): void {
    if (this.props.selectedUnitId) {
      this.requestContents(this.props.selectedUnitId);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const {selectedUnitId} = this.props;
    if (selectedUnitId && prevProps.selectedUnitId !== selectedUnitId) {
      this.requestContents(selectedUnitId);
    }
  }

  public render() {
    return (
      <React.Fragment>
        <FloatSidebar
          provider={FloatSidebarProvider.State}
          breakpoint={BootstrapBreakpoint.MD}
          position={FloatSidebarPosition.Left}
          sidebarPulledOut={this.props.sidebarPulledOut}
          collapsedButton={(clickHandler, sidebarCollapsed) => (
            <CollapsedSidebarButton
              onClick={this.props.toggleSidebar}
              shouldBeRendered={sidebarCollapsed || false}
            />
          )}
        >
          <ItemsListColumn>
            {this.props.coursebookUnits.map(this.renderCoursebookUnitItem)}
          </ItemsListColumn>
        </FloatSidebar>
        <ContentsView contents={this.props.selectedUnitId ? this.state.contents : []} />
      </React.Fragment>
    );
  }

  private renderCoursebookUnitItem = (coursebookUnit: CoursebookUnit) => {
    return (
      <LibraryPageListEl
        isActive={coursebookUnit.id === this.props.selectedUnitId}
        handleClick={() => this.props.selectUnit(coursebookUnit.id)}
        title={coursebookUnit.title}
        dropdownButtons={[]}
        alignment={LibraryListAlignment.LEFT}
        renderHandle={() => null}
        key={coursebookUnit.id}
      >
        <UnitItemTitleIcon
          isRevision={coursebookUnit.isRevision}
          ordinal={coursebookUnit.ordinal}
        />
      </LibraryPageListEl>
    );
  };

  private requestContents = (unitId: number) => {
    if (this.request) {
      this.request.cancel();
    }
    this.setState({contents: undefined});
    this.request = makeCancellable(
      this.props.requestContents(unitId),
      this.handleRequestSuccess,
      this.props.handleRequestError
    );
  };

  private handleRequestSuccess = (action: GetContentsBySectionResponse) => {
    const {coursebookSections} = this.props;
    const contents = action.payload.data.map(contentsItem => ({
      title: coursebookSections.find(
        coursebookSection => coursebookSection.id === contentsItem.coursebookSectionId
      )!.section.title,
      description: Value.fromJSON(contentsItem.description),
      id: contentsItem.coursebookSectionId
    }));
    this.setState({contents});
  };
}
