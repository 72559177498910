import React, {useRef} from 'react';
import Tooltip from 'rc-tooltip';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import {type UserV2} from '../interface';
import {coursebookLibraryMessages} from '../messages';
import Icon from '../../Icon';

import './OriginalAuthorTooltip.scss';

interface Props extends WrappedComponentProps {
  originalAuthor: UserV2;
}

const OriginalAuthorTooltip: React.FC<Props> = (props: Props) => {
  const {
    intl: {formatMessage},
    originalAuthor
  } = props;

  const ref = useRef<HTMLSpanElement>(null);

  const getTooltipContainer = () => ref.current!;

  return (
    <span className="original-author-tooltip" ref={ref}>
      <Tooltip
        destroyTooltipOnHide={true}
        overlay={
          <span>
            <div>{formatMessage(coursebookLibraryMessages.OriginalAuthor) + ': '}</div>
            {originalAuthor.profile.role === 'teacher'
              ? `${originalAuthor.profile.lastName} ${originalAuthor.profile.firstName}`
              : formatMessage(coursebookLibraryMessages.EnglexDepartmentOfMethodology)}
          </span>
        }
        placement="top"
        getTooltipContainer={getTooltipContainer}
        overlayClassName="black-tooltip"
      >
        <span className="original-author-tooltip__icon">
          <Icon name="info-circle" />
        </span>
      </Tooltip>
    </span>
  );
};

export default injectIntl(OriginalAuthorTooltip);
