import {connect} from 'react-redux';
import {type IntlShape, injectIntl} from 'react-intl';

import {type AppState} from 'store/interface';
import {CoursebookAuthorRole} from 'common/enums';

import ExtendedSearchPanel from '../components/ExtendedSearchPanel/ExtendedSearchPanel';
import {
  getMethodologyOption,
  mapAuthorsToOptions
} from '../components/ExtendedSearchPanel/filterAuthors';
import {type UserV2} from '../interface';
import {
  type ExtendedSearchPanelOwnProps,
  type ExtendedSearchPanelStateProps
} from '../components/ExtendedSearchPanel/interface';

function getAvailableAuthors(
  availableAuthors: UserV2[],
  intl: IntlShape,
  roleFilter: CoursebookAuthorRole | null
) {
  if (roleFilter === CoursebookAuthorRole.TEACHER) {
    return mapAuthorsToOptions(availableAuthors);
  }
  return [getMethodologyOption(intl), ...mapAuthorsToOptions(availableAuthors)];
}

const mapStateToProps = (
  state: AppState,
  {intl, filter, availableOriginalAuthors, availableAuthors}: ExtendedSearchPanelOwnProps
): ExtendedSearchPanelStateProps => {
  const {extendedSearchPanelOpen} = state.coursebookLibrary!;
  const hasUnpublishedBookAccess = !!state.settings?.teacher?.vircUnpublishedBookAccess;

  return {
    role: state.user.role,
    availableAuthorsOptions: getAvailableAuthors(availableAuthors, intl, filter.role),
    availableOriginalAuthorsOptions: availableOriginalAuthors && [
      getMethodologyOption(intl),
      ...mapAuthorsToOptions(availableOriginalAuthors)
    ],
    isOpen: extendedSearchPanelOpen,
    hasUnpublishedBookAccess
  };
};

const Connected = connect(mapStateToProps)(ExtendedSearchPanel);

export default injectIntl(Connected);
