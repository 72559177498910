import React from 'react';

import {type AxiosResponseAction} from 'services/axios/interface';
import {type RequestLevelsResponseAction} from 'store/exercise/editor/actions/xeditor';
import {type RequestCoursesResponseAction} from 'routes/Library/Common/CoursebookDataModal/action';
import {CoursebookAuthorRole} from 'common/enums';

import {type CoursebookLibraryProps} from './interface';
import {type CancellablePromise, makeCancellable} from '../../../helpers/cancellablePromise';
import {methodologyOptionId} from './ExtendedSearchPanel/filterAuthors';
import WampErrorMask from '../../WampErrorMask';
import Loader from '../../Loader';
import CoursebookLibraryHeader from '../containers/CoursebookLibraryHeader';
import ExtendedSearchPanel from '../containers/ExtendedSearchPanel';
import CoursebookLibraryList from './CoursebookLibraryList';
import {type RequestAuthorsResponseAction} from '../actions/action';
import './CoursebookLibrary.scss';

export default class CoursebookLibrary extends React.Component<CoursebookLibraryProps> {
  private extendedSearchDataRequest: CancellablePromise;

  public constructor(props: CoursebookLibraryProps) {
    super(props);
  }

  public componentDidMount(): void {
    this.props.clearLibrary();
    this.requestExtendedSearchData();
  }

  public componentWillUnmount(): void {
    if (this.extendedSearchDataRequest) {
      this.extendedSearchDataRequest.cancel();
    }
  }

  public componentDidUpdate() {
    const {
      filter: {authorId, role},
      changeAuthorFilter
    } = this.props;
    if (authorId === methodologyOptionId && role === CoursebookAuthorRole.TEACHER) {
      changeAuthorFilter(null);
    }
  }

  public render() {
    const {
      isUpdatingLibrary,
      libraryHasError,
      filter,
      resetFilters,
      changeTitleFilter,
      changeRoleFilter,
      changePublishedFilter,
      changeCoursesFilter,
      changeLevelsFilter,
      changeAuthorFilter,
      changeOriginalAuthorFilter,
      availableLevels,
      availableCourses,
      getEditSuccessCallback,
      title,
      createCoursebook,
      availableOriginalAuthors,
      availableAuthors,
      coursebookItemComponent,
      hasUnpublishedBookAccess
    } = this.props;
    const isValidehasUnpublishedBookAccess =
      hasUnpublishedBookAccess !== undefined && !hasUnpublishedBookAccess;

    const canResetFilter = Object.keys(filter).some(key => {
      if (key === 'title' || (isValidehasUnpublishedBookAccess && key === 'published')) {
        return false;
      }
      return filter[key] !== null;
    });

    const extendedSearchDataLoaded =
      availableAuthors && availableOriginalAuthors && availableCourses && availableLevels;

    if (
      !extendedSearchDataLoaded ||
      !availableAuthors ||
      !availableLevels ||
      !availableCourses ||
      !availableOriginalAuthors
    ) {
      if (libraryHasError) {
        return <WampErrorMask reload={this.reload} />;
      }
      return <Loader />;
    }
    return (
      <div className="coursebook-library">
        <CoursebookLibraryHeader
          titleFilter={filter.title || ''}
          changeCoursebookTitleFilter={changeTitleFilter}
          canResetFilter={canResetFilter}
          resetFilters={resetFilters}
          title={title}
          createCoursebook={createCoursebook}
        />
        <ExtendedSearchPanel
          filter={filter}
          changeCoursebookRoleFilter={changeRoleFilter}
          changeCoursebookPublishedFilter={changePublishedFilter}
          changeCoursesFilter={changeCoursesFilter}
          changeLevelsFilter={changeLevelsFilter}
          changeAuthorFilter={changeAuthorFilter}
          changeOriginalAuthorFilter={changeOriginalAuthorFilter}
          availableLevels={availableLevels}
          availableCourses={availableCourses}
          availableAuthors={availableAuthors}
          availableOriginalAuthors={availableOriginalAuthors}
        />
        <CoursebookLibraryList
          filter={filter}
          setLibraryError={this.props.setLibraryError}
          getEditSuccessCallback={getEditSuccessCallback}
          coursebookItemComponent={coursebookItemComponent}
        />
        {isUpdatingLibrary ? (
          <div className="library-updating-mask">
            <Loader />
          </div>
        ) : null}
        {libraryHasError ? <WampErrorMask reload={this.reload} /> : null}
      </div>
    );
  }

  private reload = () => {
    this.props.clearLibrary();
    this.requestExtendedSearchData();
  };

  private requestExtendedSearchData = () => {
    if (this.extendedSearchDataRequest) {
      this.extendedSearchDataRequest.cancel();
    }
    this.extendedSearchDataRequest = makeCancellable(
      Promise.all([
        this.props.requestAvailableAuthors(false),
        this.props.requestAvailableAuthors(true),
        this.props.requestAvailableLevels(),
        this.props.requestAvailableCourses()
      ]),
      this.onExtendedSearchDataLoaded,
      this.props.setLibraryError
    );
  };

  private onExtendedSearchDataLoaded = (actions: Array<AxiosResponseAction<{}>>) => {
    const {setAvailableAuthors, setAvailableCourses, setAvailableLevels, validateFilter} =
      this.props;
    const availableAuthors = (actions[0] as RequestAuthorsResponseAction).payload.data;
    const availableOriginalAuthors = (actions[1] as RequestAuthorsResponseAction).payload.data;
    const availableLevels = (actions[2] as RequestLevelsResponseAction).payload.data;
    const availableCourses = (actions[3] as RequestCoursesResponseAction).payload.data;

    if (validateFilter) {
      validateFilter(availableAuthors, availableOriginalAuthors, availableCourses, availableLevels);
    }

    setAvailableAuthors({availableAuthors, availableOriginalAuthors});
    setAvailableLevels(availableLevels);
    setAvailableCourses(availableCourses);
  };
}
