import React, {type FC, useCallback, useMemo} from 'react';
import classNames from 'classnames';

import {type UnitInstancePageContent} from '../interface';

interface Props {
  active: boolean;
  closeParent(): void;
  data: UnitInstancePageContent;
  index: number;
  selectPage(page: number): void;
}

export const ListItem: FC<Props> = ({active, closeParent, data, index, selectPage}) => {
  const {extraAvailable, exercisesCompleted, exercisesCount, title, task} = data;
  const isInstance = exercisesCompleted !== undefined;
  const printTask = useMemo(
    () => (title && task?.startsWith(title) ? task.slice(title.length + 1) : task),
    [task, title]
  );

  const onClick = useCallback(() => {
    closeParent();
    if (!active) selectPage(index);
  }, [active, closeParent, index, selectPage]);

  return (
    <li className={classNames('unit-contents-list-item', {active})} onClick={onClick}>
      <div className="index">{index}</div>
      <div className="excerpt">
        {title && <span className="title">{title} </span>}
        <span className="task">{printTask}</span>
      </div>
      {exercisesCount && (
        <div className={classNames('exercises-info', {'with-progress': isInstance})}>
          <span
            className={classNames('exercises-progress', {
              bold: isInstance && exercisesCompleted !== exercisesCount
            })}
          >
            {isInstance && <>{exercisesCompleted}/</>}
            {exercisesCount}
          </span>
          {!!extraAvailable && <span className="extras">+{extraAvailable}</span>}
        </div>
      )}
    </li>
  );
};
